import {ModuleTypes} from '@/dataObjects/frameworkObjects/ModuleTypes.js';
import moment from 'moment/moment';
import {useArea2observationStateStore} from '@/stores/area2observationState.js';
import {usePage} from '@inertiajs/vue3';
import {useArea1personalStateStore} from '@/stores/area1personalState.js';
import {useNavigationStore} from '@/stores/navigation.js';

export class AreaObject {
	/**
	 * @see App\IndiciOpponibili\DB
	 */
	static AREA1_PERSONAL = 'area1personal';
	static AREA2_OBSERVATION = 'area2observation'; //POSIZIONE 4
	static AREA3_RELATIONS = 'area3relations';
	static AREA4_ACTIVITIES = 'area4activities';  //POSIZIONE 2
	static AREA5_PLAYROOM = 'area5playroom';
	static AREA6_AUDIO = 'area6audio';

	constructor(user, props = {}) {
		this.name = props.areaName
		this.store = props.areaStore()
		this.data = props.areaData
		/**
		 * per determinare alcune accessibilita' ci servono informazioni sulle risposte di area1personal
		 */
		this.dataArea1personal = props.dataArea1personal
		this.area1personal = null
		/**
		 * per determinare l'accessibilita' di alcune aree ci servono informazioni sulle risposte di
		 * area2observation; usato da _oneMonthOfInactivityInObservationArea e _isAgesAndStages4Completed
		 */
		this.dataArea2observation = props.dataArea2observation
		this.area2observation = null

		if(this.name === AreaObject.AREA1_PERSONAL) {
			this.store.create(
				this.data.modules,
				user.answers.filter(answer => answer.area_name === AreaObject.AREA1_PERSONAL)
			)
		}
		else if(this.name === AreaObject.AREA2_OBSERVATION) {
			this.store.create(
				this.data.modules,
				user.answers.filter(answer => answer.area_name === AreaObject.AREA2_OBSERVATION),
				user.timeuse_answers,
				user.word_answers,
			)
		}
		else if(this.name === AreaObject.AREA3_RELATIONS) {
			this.store.create(
				this.data.modules,
				user.answers, // all of them!
				user.timeuse_answers,
				user.word_answers,
			)
		}
		else if(this.name === AreaObject.AREA4_ACTIVITIES) {
			this.store.create(
				this.data.modules,
				user.answers.filter(answer => answer.area_name === AreaObject.AREA4_ACTIVITIES),
				user.timeuse_answers,
				user.word_answers,
			)
		}
		else if(this.name === AreaObject.AREA5_PLAYROOM) {
			this.store.create(
				this.data.modules,
				user.answers.filter(answer => answer.area_name === AreaObject.AREA5_PLAYROOM),
				user.timeuse_answers,
				user.word_answers,
			)
		}
		else if(this.name === AreaObject.AREA6_AUDIO) {
			this.store.create(
				this.data.modules,
				user.answers.filter(answer => answer.area_name === AreaObject.AREA6_AUDIO),
				user.timeuse_answers,
				user.word_answers,
			)
		}

		this.modules = this.store.modules
		this.questions = this.store.questions
	}

	completionPerc() {
		const completedModules = this.store.modules.filter(module => module.isCompleted())
		return 100 * completedModules.length / this.store.modules.length
	}

	/**
	 * Appunti (osservazione): è aperta, internamente le sue tappe ha blocchi temporali
	 *
	 * Ascolta: è aperta, internamente le sue tappe ha blocchi temporali
	 *
	 * Parole: questa è già in home si attiva e colora al primo inserimento di PVB.
	 *
	 * POSSIBILITA': sezioni Ricreazione (attività), Osserva (relazioni, report), Ludoteca (invariato), Ascolta
	 *    partono bloccate e si possono aprire nel momento in cui abbiamo compilato ages and stages quarta tappa 23-25 mesi;
	 *    Oppure se Presentazioni è compilato e il bimbo ha 25 mesi
	 *
	 * @param childBirthdate {null|Moment}
	 * @returns {boolean}
	 */
	isAccessible(childBirthdate = null){
		if(this.name === AreaObject.AREA1_PERSONAL) {
			return true
		}

		if(this.name === AreaObject.AREA2_OBSERVATION) {
			return this._isArea1personalCompleted()
		}

		if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}

		const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()

		//relazioni, attivita, ludoteca e ascolta
		return (
			this._isBehaviorsCompleted(2) && this._isPhrasesCompleted(3) && this._isAgesAndStagesCompleted(4) || // 24 mesi
			this._isBehaviorsCompleted(6) && this._isPhrasesCompleted(7) && this._isAgesAndStagesCompleted(8) || // 30 mesi
			this._isBehaviorsCompleted(10) && this._isPhrasesCompleted(11) && this._isAgesAndStagesCompleted(12) || // 36 mesi
			(this._isArea1personalCompleted() && nowCalc.diff(childBirthdate, 'months') >= 25)
		)
	}

    _isBehaviorsCompleted(moduleNum) {
		this._fillArea2observation()

		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_BEHAVIORS &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
			return true
		}
		return false
	}

	_isPhrasesCompleted(moduleNum) {
		this._fillArea2observation()

		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_PHRASES &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
			return true
		}
		return false
	}

	_isAgesAndStagesCompleted(moduleNum) {
		this._fillArea2observation()

		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_AGES_AND_STAGES &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
			return true
		}
		return false
	}

	/**
	 * un mese che il genitore non compila observation.AgesAndStages4
	 * @returns {boolean}
	 * @private
	 */
	_oneMonthOfInactivityInObservationArea() {
		const oneMonthAgo = moment().endOf('day').subtract(1, 'months')

		this._fillArea2observation()

		const answeredAgesAndStageQuestions = this.area2observation.store.questions.filter(question => {
			return (
				question.page.module.type === ModuleTypes.TYPE_AGES_AND_STAGES &&
				question.page.module.index === 4 &&
				question.isAnswered()
			)
		})

		return (
			answeredAgesAndStageQuestions.length > 0 &&
			answeredAgesAndStageQuestions.every(question => moment(question.dt).isBefore(oneMonthAgo))
		)
	}

	_isArea1personalCompleted() {
		this._fillArea1personal()
		return this.area1personal.completionPerc() === 100
	}

	_fillArea1personal() {
		if(this.name === AreaObject.AREA1_PERSONAL || this.area1personal) {
			return
		}

		this.area1personal = new AreaObject(usePage().props.user, {
			areaName: AreaObject.AREA1_PERSONAL,
			areaStore: useArea1personalStateStore,
			areaData: this.dataArea1personal
		})
	}

	_fillArea2observation() {
		if(this.name === AreaObject.AREA2_OBSERVATION || this.area2observation) {
			return
		}

		this.area2observation = new AreaObject(usePage().props.user, {
			areaName: AreaObject.AREA2_OBSERVATION,
			areaStore: useArea2observationStateStore,
			areaData: this.dataArea2observation
		})
	}
}
