<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import MicroModal from 'micromodal';
import {onMounted} from 'vue';
import ModalSetLanguage from '@/Pages/sharedComponents/modalSetLanguage.vue';
import {usePage} from '@inertiajs/vue3';
import {compClass} from '@/libraries/utils.js';

const languageIsSetted = !!usePage().props.user?.lang

if(!languageIsSetted) {
	MicroModal.init()

	onMounted(() => {
		MicroModal.show('modal-setLanguage', {
			disableClose: true,
		})
	})
}
</script>

<template>
	<AppLayout>
		<div :class="compClass()" class="page">

			<article class="py-8 px-12 text-2xl"
				v-html="$tg('welcomePage') "
			/>

		</div>
		<ModalSetLanguage
			v-if="!languageIsSetted"
		/>
	</AppLayout>
</template>
