<script setup>
import { Rive, Layout } from '@rive-app/canvas';
import { markRaw, onMounted, ref } from 'vue';
import { timeout } from '@/libraries/utils';

const riveCanvas = ref(null)

const riveInstance = markRaw({})
const widthInput = markRaw({})
const heightInput = markRaw({})

const props = defineProps({
	src: String,
	fit: String,
	alignment: String,
	artboard: {
		type: String,
		default: null
	},
	statemachine: {
		type: String,
		default: null
	},
	responsive: {
		type: Boolean,
		default: true
	},
	autoplay: {
		type: Boolean,
		default: false
	},
	responsiveWidthController: {
		type: String,
		default: "widthinput"
	},
	responsiveHeightController: {
		type: String,
		default: "heightinput"
	},
})

defineExpose({
	playAnimation: (animationName) => {
		console.debug("[RiveComponent] playAnimation", animationName);
		riveInstance.value.play(animationName)
	},
	pauseAnimation: (animationName) => {
		console.debug("[RiveComponent] pauseAnimation", animationName);

		//migliaia di errori "StateMachine exceeded max iterations."
		// if(useMainStateStore().environment === 'local') {
		// 	riveInstance.value.pause(animationName)
		// }
	},
	// cleanUp: () => {
	// 	console.debug("[RiveComponent] cleanUp");
	// 	console.debug(riveInstance.value);
	// 	console.debug(riveInstance.value.cleanUp);
	// 	// riveInstance.value.cleanUp()
	// },
	trigger: (triggerName) => {
		console.debug("[RiveComponent] trigger", triggerName);
		const inputs = riveInstance.value.stateMachineInputs(props.statemachine);
		const triggerInput = inputs.find(i => i.name === triggerName);
		triggerInput.fire();
	},
	boolean: (booleanName, booleanValue) => {
		console.debug("[RiveComponent] boolean", booleanName, booleanValue);
		const inputs = riveInstance.value.stateMachineInputs(props.statemachine);
		const booleanInput = inputs.find(i => i.name === booleanName);
		booleanInput.value = booleanValue;
	},
	number: (numberName, numberValue) => {
		try {
			console.debug("[RiveComponent] number", numberName, numberValue);
			const inputs = riveInstance.value.stateMachineInputs(props.statemachine);
			const numberInput = inputs.find(i => i.name === numberName);
			if(!numberInput) {
				console.error("[RiveComponent] number, no numberInput", numberName, numberValue, e)
			}
			numberInput.value = numberValue;
		} catch(e) {
			console.error("[RiveComponent] number:", numberName, numberValue, e)
		}
	}
})

let oldEvent = null, counter = 0
onMounted(() => {
	const riveAssetRequest = new Request(props.src);
	fetch(riveAssetRequest)
	.then((riveAsset) => {
		return riveAsset.arrayBuffer();
	})
	.then((riveAssetBuffer) => {
		riveInstance.value = new Rive({
			canvas: riveCanvas.value,
			src: props.src,
			buffer: riveAssetBuffer,
			artboard: props.artboard,
			stateMachines: props.statemachine,
			layout: new Layout({
				fit: props.fit,
				alignment: props.alignment,
			}),
			onLoad: () => {
				try {
					const inputs = riveInstance.value.stateMachineInputs(props.statemachine)
					if(inputs?.length) {
						console.debug("[RiveComponent] INPUTS:", inputs.length);
						inputs.forEach(i => console.debug(i.name))
						if(props.responsive) {
							if(props.responsiveWidthController) {
								widthInput.value = inputs.find(i => i.name === props.responsiveWidthController);
								if(widthInput.value) {
									widthInput.value.value = riveCanvas.value.width;
								}
							}
							if(props.responsiveHeightController) {
								heightInput.value = inputs.find(i => i.name === props.responsiveHeightController);
								if(heightInput.value) {
									heightInput.value.value = riveCanvas.value.height;
								}
							}
						}
					}
				} catch(e) {
					//altrimenti gli errori di questa parte vengono mostrati come "Problem loading file; may be corrupt!"
					console.error("[RiveComponent] onLoad:", e)
				}
			},
			onPlay: (event) => {
				riveInstance.value.resizeDrawingSurfaceToCanvas();
				console.debug("[RiveComponent] Play:", event)
			},
			onPause: (event) => {
				console.debug("[RiveComponent] Pause:", event)
			},
			/**
			 * in pop-famiglia-nonni-multi si ripresenta lo statechange infinito. Chiamo una
			 * funzione inesistente, perché in caso di errore lo statechange infinito si interrompe
			 *
			 * ho tolto la chiamata d'errore, forse non serve più e non va bene con la ruota che
			 * può essere girata infinite volte.
			 */
			onStateChange: (event) => {
				counter++
				console.debug("[RiveComponent] State change:", event.data === oldEvent?.data, event.data, oldEvent?.data)
				// if(event.data === oldEvent?.data) {
				// 	a()
				// }
				// if(counter > 10) {
				// 	a()
				// }
				//oldEvent = event
			},
			// onAdvance: (event) => {
			//     console.debug("[RiveComponent] Advance: ", event)
			// },
			autoplay: props.autoplay
		});
	});

	// window.addEventListener("resize", () => {
	// 	riveInstance.value.resizeDrawingSurfaceToCanvas();
	// 	if(props.responsive && widthInput.value && heightInput.value) {
	// 		widthInput.value.value = riveCanvas.value.width;
	// 		heightInput.value.value = riveCanvas.value.height;
	// 	}
	// });
})


async function isReadyFunc() {
    // Martellata for the win
    console.debug("Loading Rive Component...")
    await timeout(3000)
    console.debug("Loading completed")
    return true
}

await isReadyFunc()  // this makes the component Async => Suspense can be used (https://vuejs.org/api/sfc-script-setup#top-level-await)
</script>

<template>
	<canvas class="rive-canvas" ref="riveCanvas"></canvas>
</template>

<style scoped>
.rive-canvas{
	@apply my-auto;
	@apply h-full w-full;
	@apply max-h-full max-w-full;
}
</style>
