import { defineStore } from 'pinia'
import { UserObject } from '@/dataObjects/UserObject'
import { useNavigationStore } from './navigation'
import moment from 'moment'

export const useMainStateStore = defineStore('mainState', {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			browserSessionId: '',
			translateGender: '',
			user: new UserObject(),
            childBirthdate: null,
			environment: (() => {
				if(location.hostname === '127.0.0.1' || location.hostname === 'localhost') {
					return 'local'
				}
				if(location.hostname.indexOf('dev.') === 0) {
					return 'dev'
				}
				if(location.hostname.indexOf('staging.') === 0) {
					return 'staging'
				}

				return 'production'
			})()
		}
	},
	/*FUNZIONI*/
	// since we rely on `this`, we cannot use an arrow function
	actions: {
		/**
		 * 'other' e 'none' fanno parte del maschile sovraesteso, ovvero 'm'
		 * @param translateGender {string} 'female' 'male' 'other' e 'none'
		 */
		setTranslateGender(translateGender) {
			//'female' > 'f'
			this.translateGender = translateGender[0] === 'f' ? 'f' : 'm'
		},
	},
	/*VARIABILI CALCOLATE*/
	// since we rely on `this`, we cannot use an arrow function
	setters: {
	},
	getters: {
        apparentChildAgeInMonths() {
            let fakeNow = useNavigationStore().fakeNow
            if(fakeNow){
                return moment.duration(moment(fakeNow).diff(moment(this.childBirthdate))).asMonths()
            } else {
                return moment.duration(moment().diff(moment(this.childBirthdate))).asMonths()
            }
        }
	},
	persist: true
})
