import { browserDt } from "@/libraries/utils";
import { BasePageObject } from "../../frameworkObjects/BasePageObject";
import {saveRemoteWordAnswers} from '@/libraries/network.js';

export class WordsPageObject extends BasePageObject {

    static STATUS_OTHER_PRONUNCIATION = 'other-pronunciation'
    static STATUS_OTHER_LANGUAGE = 'other-language'
    static STATUS_CORRECTLY_SAID = 'correctly-said'

    static CUSTOM_CONTAINER = 'custom'
    static CUSTOM_INDEX = 0
    static CUSTOM_STATUS = 'custom'

    _initializeQuestions(pageData, props) {
        // override default! The "questions" are actually the word objects
        this.words = props.words

	    //serve a far arrivare tutte le domande nello store
	    if(!this.parent.words) {
		    this.parent.words = []
	    }
	    //todo this.words.forEach(word => this.parent.store.words.push(word))
	    this.words.forEach(word => this.parent.words.push(word))
    }

    isCompleted() {
        // can't be completed
        return false
    }

    isAccessible() {
        // if the module is accessible, it's accessible
        return true
    }

    isNotSeen() {
        // doesn't mean anything
        return false
    }

    async save(wordContainer, wordIndex, wordValue, wordStatus) {
        // custom save function
        console.debug("WordsPageObject.save", wordContainer, wordIndex, wordValue, wordStatus)
        let wordAnswerObject = {
            'word_container': wordContainer,
            'word_index': wordIndex,
            'word_status': wordStatus,
            'word': wordValue.toLowerCase(),
            'browser_dt': browserDt()
        }

        // Makes sure the word status is updated locally
        console.debug("WordsPageObject.save this.words OLD", this.words)
        let wordFound = false
        this.words.forEach(word =>{
            if (word.word == wordAnswerObject.word){
                word.word_status = wordAnswerObject.word_status
                wordFound = true
            }
        })
        if(!wordFound){
            // newly added word. Not the same object but good enough
            this.words.push(wordAnswerObject)
        }
        this.parent.words = []
        this.words.forEach(word => this.parent.words.push(word))
        console.debug("WordsPageObject.save this.words NEW", this.words)

        try {
            await saveRemoteWordAnswers(
                [wordAnswerObject]
            )
        } catch(e) {
            console.error('WordsPageObject.save catch', e);
        }
    }

};
