/**
 * il nome delle rotte combacia con il TimeuseModuleObject.id
 * @see resources/js/dataObjects/area1personal/ModuleObject.js
 */

import { createRouter, createWebHistory } from "vue-router";
import {dataArea1personal} from '@/config/dataArea1personal.js'
import {dataArea2observation} from '@/config/dataArea2observation.js'
import {dataArea3relations} from '@/config/dataArea3relations.js'
import {dataArea4activities} from '@/config/dataArea4activities.js';
import {dataArea5playroom} from '@/config/dataArea5playroom.js';
import {dataArea6audio} from '@/config/dataArea6audio.js';
import area1personal from '@/Pages/area1personal/modulesList.vue'
import area2observation from '@/Pages/area2observation/modulesList.vue'
import area3relations from '@/Pages/area3relations/modulesList.vue'
import area4relations from '@/Pages/area4activities/modulesList.vue'
import area5playroom from '@/Pages/area5playroom/modulesList.vue'
import area6audio from '@/Pages/area6audio/modulesList.vue'
import home from '@/Pages/home.vue';
import about from '@/Pages/about.vue';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import welcome from '@/Pages/welcome.vue';

//abbiamo bisogno di una closure per ogni area, con le proprie variabili, ma con le funzioni dichiarate
// e immediatamente chiamate c'e' uno strano errore. La soluzione e' assegnarle a una variabile.
let a

//init with home routes
const routes = [
	{
		path: '/',
		component: home,
		name: 'home'
	},
	{
		path: '/welcome',
		component: welcome,
		name: 'welcome'
	},
	{
		path: '/about',
		component: about,
		name: 'about'
	},
]

//AREA PRESENTAZIONI
a = (() => {
	const areaName = AreaObject.AREA1_PERSONAL
	routes.push({
		path: `/${areaName}`,
		component: area1personal,
		name: areaName
	})

	dataArea1personal.modules.forEach((module, mIndex) => {
		//moduli iniziano da 1
		mIndex++
		/**
		 * queste proprieta arrivano ai componenti pageN.vue
		 * @type {{mIndex: number}}
		 */
		const mProps = {
			mIndex: mIndex,
		}

		//push page routes
		module.pages.forEach((page, pIndex) => {
			//non si puo usare lo store qui perche: Are you trying to use a store before calling "app.use(pinia)"?

			//pagine iniziano da 1
			pIndex++
			const pProps = {
				...mProps,
				pIndex: pIndex
			}

			routes.push({
				path: `/${areaName}/modules/module${mIndex}/page${pIndex}`,
				//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
				//non posso usare basePathModules nella costruzione della stringa della FUNZIONE import, perché non accetta
				//variabili che contengano piu di un livello del path (es. '/area1personal/modules'), lo fa per il nostro bene :D
				component: () => import(`@/Pages/${areaName}/modules/module${mIndex}/page${pIndex}.vue`),
				name: `${areaName}_module${mIndex}_page${pIndex}`,
				props: pProps
			})
		})
	})
	return true
})();

//AREA ATTIVITÀ
a = (() => {
	const areaName = AreaObject.AREA4_ACTIVITIES
	routes.push({
		path: `/${areaName}`,
		component: area4relations,
		name: areaName
	})

	dataArea4activities.modules.forEach((module, mIndex) => {
		//moduli iniziano da 1
		mIndex++

		/**
		 * queste proprieta arrivano ai componenti pageN.vue
		 * @type {{mIndex: number}}
		 */
		const mProps = {
			mIndex: mIndex,
		}

		//push page routes
		module.pages.forEach((page, pIndex) => {
			//non si puo usare lo store qui perche: Are you trying to use a store before calling "app.use(pinia)"?

			//pagine iniziano da 1
			pIndex++
			const pProps = {
				...mProps,
				pIndex: pIndex
			}

			routes.push({
				path: `/${areaName}/modules/module${mIndex}/page${pIndex}`,
				//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
				//non posso usare basePathModules nella costruzione della stringa della FUNZIONE import, perché non accetta
				//variabili che contengano piu di un livello del path (es. '/area1personal/modules'), lo fa per il nostro bene :D
				component: () => import(`@/Pages/${areaName}/modules/module${mIndex}/page${pIndex}.vue`),
				name: `${areaName}_module${mIndex}_page${pIndex}`,
				props: pProps
			})
		})
	})

	return true
})();

//AREA RELAZIONI
a = (() => {
	const areaName = AreaObject.AREA3_RELATIONS
	routes.push({
		path: `/${areaName}`,
		component: area3relations,
		name: areaName
	})

	dataArea3relations.modules.forEach((module, mIndex) => {
		//moduli iniziano da 1
		mIndex++
		// const moduleTypeName = (() => {
		// 	switch (module.type) {
		// 		case (ModuleTypes.TYPE_TIMEUSE): return 'timeuse'
		// 		default: return null
		// 	}
		// })()
		// if(moduleTypeName !== 'timeuse') {
		// 	return null
		// }

		/**
		 * queste proprieta arrivano ai componenti pageN.vue
		 * @type {{mIndex: number}}
		 */
		const mProps = {
			mIndex: mIndex,
		}

		//push page routes
		module.pages.forEach((page, pIndex) => {
			//non si puo usare lo store qui perche: Are you trying to use a store before calling "app.use(pinia)"?

			//pagine iniziano da 1
			pIndex++
			const pProps = {
				...mProps,
				pIndex: pIndex
			}

			routes.push({
				path: `/${areaName}/modules/module${mIndex}/page${pIndex}`,
				//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
				//non posso usare basePathModules nella costruzione della stringa della FUNZIONE import, perché non accetta
				//variabili che contengano piu di un livello del path (es. '/area1personal/modules'), lo fa per il nostro bene :D
				component: () => import(`@/Pages/${areaName}/modules/module${mIndex}/page${pIndex}.vue`),
				name: `${areaName}_module${mIndex}_page${pIndex}`,
				props: pProps
			})
		})
	})

	return true
})();

//AREA OSSERVAZIONE
a = (() => {
	const areaName = AreaObject.AREA2_OBSERVATION
	routes.push({
		path: `/${areaName}`,
		component: area2observation,
		name: areaName
	})

	dataArea2observation.modules.forEach((module, mIndex) => {
		//moduli iniziano da 1
		mIndex++
		// const moduleTypeName = (() => {
		// 	switch (module.type) {
		// 		case (ModuleTypes.TYPE_TIMEUSE): return 'timeuse'
		// 		default: return null
		// 	}
		// })()
		// if(moduleTypeName !== 'timeuse') {
		// 	return null
		// }

		/**
		 * queste proprieta arrivano ai componenti pageN.vue
		 * @type {{mIndex: number}}
		 */
		const mProps = {
			mIndex: mIndex,
		}

		//push page routes
		module.pages.forEach((page, pIndex) => {
			//non si puo usare lo store qui perche: Are you trying to use a store before calling "app.use(pinia)"?

			//pagine iniziano da 1
			pIndex++
			const pProps = {
				...mProps,
				pIndex: pIndex
			}

			routes.push({
				path: `/${areaName}/modules/module${mIndex}/page${pIndex}`,
				//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
				//non posso usare basePathModules nella costruzione della stringa della FUNZIONE import, perché non accetta
				//variabili che contengano piu di un livello del path (es. '/area1personal/modules'), lo fa per il nostro bene :D
				component: () => import(`@/Pages/${areaName}/modules/module${mIndex}/page${pIndex}.vue`),
				name: `${areaName}_module${mIndex}_page${pIndex}`,
				props: pProps
			})
		})
	})

	return true
})();

//AREA LUDOTECA
a = (() => {
	const areaName = AreaObject.AREA5_PLAYROOM
	routes.push({
		path: `/${areaName}`,
		component: area5playroom,
		name: areaName
	})

	dataArea5playroom.modules.forEach((module, mIndex) => {
		//moduli iniziano da 1
		mIndex++

		/**
		 * queste proprieta arrivano ai componenti pageN.vue
		 * @type {{mIndex: number}}
		 */
		const mProps = {
			mIndex: mIndex,
		}

		//push page routes
		module.pages.forEach((page, pIndex) => {
			//non si puo usare lo store qui perche: Are you trying to use a store before calling "app.use(pinia)"?

			//pagine iniziano da 1
			pIndex++
			const pProps = {
				...mProps,
				pIndex: pIndex
			}

			routes.push({
				path: `/${areaName}/modules/module${mIndex}/page${pIndex}`,
				//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
				//non posso usare basePathModules nella costruzione della stringa della FUNZIONE import, perché non accetta
				//variabili che contengano piu di un livello del path (es. '/area1personal/modules'), lo fa per il nostro bene :D
				component: () => import(`@/Pages/${areaName}/modules/module${mIndex}/page${pIndex}.vue`),
				name: `${areaName}_module${mIndex}_page${pIndex}`,
				props: pProps
			})
		})
	})

	return true
})();

//AREA AUDIO
a = (() => {
	const areaName = AreaObject.AREA6_AUDIO
	routes.push({
		path: `/${areaName}`,
		component: area6audio,
		name: areaName
	})

	dataArea6audio.modules.forEach((module, mIndex) => {
		//moduli iniziano da 1
		mIndex++

		/**
		 * queste proprieta arrivano ai componenti pageN.vue
		 * @type {{mIndex: number}}
		 */
		const mProps = {
			mIndex: mIndex,
		}

		//push page routes
		module.pages.forEach((page, pIndex) => {
			//non si puo usare lo store qui perche: Are you trying to use a store before calling "app.use(pinia)"?

			//pagine iniziano da 1
			pIndex++
			const pProps = {
				...mProps,
				pIndex: pIndex
			}

			routes.push({
				path: `/${areaName}/modules/module${mIndex}/page${pIndex}`,
				//invece delle static import uso le dynamic import https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
				//non posso usare basePathModules nella costruzione della stringa della FUNZIONE import, perché non accetta
				//variabili che contengano piu di un livello del path (es. '/area1personal/modules'), lo fa per il nostro bene :D
				component: () => import(`@/Pages/${areaName}/modules/module${mIndex}/page${pIndex}.vue`),
				name: `${areaName}_module${mIndex}_page${pIndex}`,
				props: pProps
			})
		})
	})

	return true
})();
// console.debug('routes', routes)

//https://stackoverflow.com/questions/48111573/ multiple router
export const router = createRouter({
	history: createWebHistory(),
	routes: routes
})
