<script setup>
/**
 * AppLayout è letteralmente un vestito da far indossare alle pagine, con il vantaggio di essere unico
 * e quindi riutilizzabile. Essendo un vestito, ha gerarchia inferiore alla pagina che lo indossa.
 * Non solo, avendo lo slot dentro, viene creato (setup) DOPO la pagina, quindi non è adatto ad
 * accogliere la gestione dei dati (es. creazione delle instanze di TimeuseModuleObject e la sua Hydrate),
 * ma solo tutto quello che riguarda la grafica, come le pezze messe con jQuery.
 */
import Menu from '@/Pages/sharedComponents/menu.vue';
import $ from 'jquery'
import {useMainStateStore} from '@/stores/mainState.js';
import {dataArea1personal} from '@/config/dataArea1personal.js';
import {onMounted, ref} from 'vue';
import {useNavigationStore} from '@/stores/navigation.js';
import moment from 'moment/moment';
import WavyTitle from '@/Pages/sharedComponents/wavyTitle.vue';
import {timeout} from '@/libraries/utils.js';

const mainStore = useMainStateStore()
const navigationStore = useNavigationStore()

const header = ref(null)
const headerFake = ref(null)
const main = ref(null)

const headerFix = () => {
	const headerFakeEl = $(headerFake.value);
	const headerEl = $(header.value);
	const mainEl = $(main.value);
	const headerH = Math.round(headerEl.height()) - 1

	headerFakeEl.css('padding-bottom', headerH + 'px');
	mainEl.height(document.body.clientHeight - headerH);
}

onMounted(async () => {
	headerFix()

	//pole pole
	await timeout(2000)
	headerFix()

	//pole pole pole pole
	await timeout(2000)
	headerFix()

	window.addEventListener('resize', headerFix)
})

const headerClasses = () => ({
	//'z-10': !areaStore.isVideoLightboxVisible
})

//debug with devtools
if(mainStore.environment !== 'production') {
	window.moment = moment
	window.__data__ = dataArea1personal
	window.__navigationStore__ = navigationStore
}

/**
 * In caso di creazione dinamica di una classe colore come in tagList.vue con
 * 'bg-' + (() => 'fuchsia')()
 * tailwind non si accorge di doverla importare nel proprio css dinamico (tag style), al contrario di quanto accade
 * quando usiamo delle classi dichiarate in modo statico. Creare un div display:none con tutte le classi colore
 * in Applayout.vue risolve.
 */
const colorsClasses = () => ({
	'bg-blue': true,
	'bg-fuchsia': true,
	'bg-green': true,
	'bg-purple': true,
	'bg-pink': true,
	'bg-yellow': true,
	'bg-purplelight': true,
	'bg-pinklight': true,
	'bg-orange': true,
	'bg-bluelight': true,
	'bg-ivory': true,
	'bg-orangedark': true,
	'bg-white': true,
	'bg-gray': true,
	'bg-black': true,
	//
	'color-blue': true,
	'color-fuchsia': true,
	'color-green': true,
	'color-purple': true,
	'color-pink': true,
	'color-yellow': true,
	'color-purplelight': true,
	'color-pinklight': true,
	'color-orange': true,
	'color-bluelight': true,
	'color-ivory': true,
	'color-orangedark': true,
	'color-white': true,
	'color-gray': true,
	'color-black': true,
})

//la testata, menu e piede sono fuori dal wrapper per essere estendibili a 100vw in desktop
</script>
<template>

	<div class="flex flex-col h-screen text-2xl font-medium text-black"
		:data-component="$page.component">
		<header v-if="$page.component !== 'login'"
			ref="header"
			class="fixed w-full bg-blue z-10" :class="headerClasses()">

			<Menu/>

			{{/*The teleport to target must be already in the DOM when the Teleport component is mounted. Ideally, this should be an element outside the entire Vue application. If targeting another element rendered by Vue, you need to make sure that element is mounted before the Teleport. */''}}
			<div v-if="['welcome', 'home', 'about'].includes($page.component)" class="z-0 bg-blue">
				<div v-if="['welcome', 'about'].includes($page.component)">
					<div class="flex justify-center pb-4">
						{{/*imposto l'altezza coi css, altrimenti headerEl.height() non riesce a conoscere l'altezza dell'immagine*/''}}
						<img class="w-auto h-28" src="/img/logo.png" alt="app parola"/>
					</div>
				</div>
				<div v-else-if="$page.component === 'home'">
					<WavyTitle
						class="pb-4 text-6xl leading-tight font-poppins font-bold text-center text-yellow"
						:text="'home'"
					/>
				</div>
			</div>
		</header>

		<div ref="headerFake" class="header-fake basis-auto"/>

		<main class="wrapper basis-full" ref="main">
			<div class="bounds h-full">
				<slot />
			</div>
		</main>

		<footer v-if="['welcome'].includes($page.component)">
			<div v-if="$page.component === 'welcome'" class="bg-blue">
				<div class="wrapper">
					<div class="bounds">
						<div class="px-20 py-6">
							<a class="w-full btn font-bold bg-white text-blue rounded-2xl" href="/">
								{{$tg('letsStart!')}}
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>

	<div :class="colorsClasses()" style="display: none"/>
</template>
