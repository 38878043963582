import {getNextId, getPrevId} from '@/libraries/navUtils.js';
import {saveRemoteTimeuseAnswers} from '@/libraries/network.js';
import {usePage} from '@inertiajs/vue3';
import {useMainStateStore} from '@/stores/mainState.js';
import {useArea2observationStateStore} from '@/stores/area2observationState.js';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import {TimeuseModuleObject} from '@/dataObjects/area2observation/timeuse/TimeuseModuleObject.js';
import {LoopObject} from '@/dataObjects/area2observation/timeuse/LoopObject.js';

export class TimeusePageObject {

	constructor(pageData, parent, props = {}) {
		this.parent = this.timeuse = this.module = parent
		this.index = props.index + 1 //index da 1
		this.isFirst = props.isFirst
		this.isLast = props.isLast

		this.id = `${this.parent.id}_page${this.index}`
		//perche ho scelto dei moduleData.id (es. 'family') diversi dal canonico 'module1'?
		//anche lang
		this.dataId = pageData.id
		this.title = pageData.title
		this.title1 = pageData.title1
		this.title2 = pageData.title2
		this.if = pageData.if
		this.mainStore = useMainStateStore()
		this.areaStore = useArea2observationStateStore()
		this.navigationStore = parent.navigationStore
		/**
		 * @type {LoopObject[]}
		 */
		this.loops = []

		this._pageDataQuestions = pageData.questions

		//creo un LoopObject vuoto che verrà riempito con le domande
		//per poter valorizzare la pagina, almeno uno
		//this.createNewLoop()
		//console.log(this.loops.length)
	}

	/**
	 * nuovo loop a partire da pageDataQuestions, da idratare
	 * @param dbAnswers
	 * @returns {LoopObject}
	 */
	createNewLoop(dbAnswers = []) {
		const newLoop = new LoopObject(this._pageDataQuestions, this, {
			index: this.loops.length + 1,
		})

		this.loops.push(newLoop)

		return newLoop
	}

	/**
	 * @returns {boolean}
	 */
	isCompleted() {
		if(this.isActivitiesPage()) {
			return this.timeuse.areActivityLoopsCompleted()
		}
		return !!this.lastLoop()?.isCompleted()
	}

	/**
	 * pagina con almeno una risposta non vista
	 * @returns {boolean}
	 */
	isNotSeen() {
		return !!this.lastLoop()?.questions.find(question => {
			//considera solo le risposte salvate sul db remoto
			return question.isNotSeen(true)
		})
	}

	isActivitiesPage() {
		return this.index === TimeuseModuleObject.ACTIVITY_LOOPS_PAGE_INDEX
	}

	/**
	 * pagina successiva o precedente (in base a direction)
	 * @param direction {String} 'prev' or 'next'
	 * @returns {PageObject|undefined}
	 */
	nearPage(direction) {
		const pageId = direction === 'next' ? getNextId(this.id) : getPrevId(this.id)
		return this.parent.pages.find(page => page.id === pageId)
	}

	/**
	 * @returns {Promise<string>}
	 */
	async goToNearPage(direction) {
		const areaName = AreaObject.AREA2_OBSERVATION
		//first or last page, go to area
		if(
 			(direction === 'prev' && this.isFirst) ||
			(direction === 'next' && this.isLast)
		) {
			await this.navigationStore.goToPage(areaName)
		}
		else {
			let page = this.parent.getPageById(
				direction === 'prev' ? getPrevId(this.id) : getNextId(this.id)
			)

			await this.navigationStore.goToPage(page?.id || areaName)
		}
	}

	/**
	 * Salva le eventuali domande zozze
	 * @returns {Promise<void>}
	 */
	async save() {
		const questions = this.lastLoop().questions
		//domande con nuova risposta da salvare
		const answersToSave = questions.filter(question => question.canBeSavedToDb())

		if(answersToSave.length) {
			try {
				await saveRemoteTimeuseAnswers(
					answersToSave.map(question => question.answerDbFormat(this))
				)
			} catch(e) {
				console.error('Page.save catch', this, e);
			}

			//console.log('pageSaved', usePage().props?.user?.achievements.length)

			// hydrate timeuse.questions
			this.parent.hydrate(usePage().props.user.timeuse_answers)
		}
	}

	/**
	 * un po' di marasma, non ho capito se la sintassi mi faceva perdere i riferimenti alle domande,
	 * lascio anche il vecchio, commentato
	 * @returns {LoopObject|null}
	 */
	lastLoop() {
		return this.loops.length
			? this.loops.find((loop, index) => index === (this.loops.length - 1))
			: null
		//return this.loops.reverse()[0]
		// return this.loops.length && this.loops[this.loops.length - 1]
	}

	/**
	 * @param force {boolean} true per il debug
	 */
	setAsSeen(force = false) {
		this.lastLoop().questions.forEach(question => question.setAsSeen(force))
	}

	getQuestionsBySupertype(questionSupertype) {
		return this.lastLoop().questions.filter(question => question.supertype === questionSupertype)
	}

	//debug start
	complete() {
		this.lastLoop().questions.forEach(question => question.complete())
	}
	setAsNotSeen() {
		this.lastLoop().questions.forEach(question => question.setAsNotSeen())
	}
	//debug end

	/**
	 * non riesco ad avere un controllo completo sui flussi, quindi mi trovo piu di un solo
	 * loop vuoto, semplifico e bonanottealsecchio.
	 * E invece no! Senza controllo sui flussi, si sfascia ogni 6, ovvero ogni 3x2.
	 * quindi scarto
	 * @returns {boolean}
	 */
	hasEmptyLoop() {
		return this.loops.length && !!this.loops.find(loop => loop.isEmpty)
	}
}

