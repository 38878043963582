import { BasePageObject } from "../frameworkObjects/BasePageObject";

export class PageObject extends BasePageObject {


    _initializeQuestions(pageData, props) {
        // override default! The "questions" are actually the word objects
        this.answers = props.answers
        this.timeuseAnswers = props.timeuseAnswers
        this.wordAnswers = props.wordAnswers
    }

    isCompleted() {
        // can't be completed
        return false
    }

    isAccessible() {
        // if the module is accessible, it's accessible
        return true
    }

    isNotSeen() {
        // doesn't mean anything
        return false
    }

    save() {
        // doesn't save anything
    }
}
