<script	setup>
import Modal from '@/Pages/sharedComponents/modal.vue';

const props = defineProps({
})
const id = 'badObjectWeekday'
</script>

<template>
	<Modal :id="id">
		<article v-html="$tg('area2observation.timeuse.modalNotAccessible')" />

		<div class="btn w-2/3 mt-8 bg-white text-blue"
			data-micromodal-close aria-label="Close modal">
			{{ $tg('continue')}}
		</div>
	</Modal>
</template>
