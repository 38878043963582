export class ModuleTypes {
	static TYPE_STANDARD = 'standard'
	static TYPE_WORDS = 'words'
	static TYPE_BEHAVIORS = 'behaviors'
	static TYPE_PHRASES = 'phrases'
	static TYPE_AGES_AND_STAGES = 'ages_and_stages'
	static TYPE_TIMEUSE = 'timeuse'
	static TYPE_REPORT = 'report'

}
