import { BaseModuleObject } from "../../frameworkObjects/BaseModuleObject";
import { WordsPageObject } from "./WordsPageObject.js"

export class WordsModuleObject extends BaseModuleObject {

    _initializePages(moduleData, props) {
        if(this.isInitialized){
            return
        }
        this.pages = moduleData.pages?.map((page, index) => {
            return new WordsPageObject(page, this, {
                index: index,
                isFirst: index === 0,
                isLast: index === moduleData.pages.length - 1,
                areaStoreFunction: props.areaStoreFunction,
                areaName: this.areaName,
                words: props.words // only difference!
            }) || []
        })

	    this.isInitialized = true
    }
};
