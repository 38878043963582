<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import { dataArea1personal } from '@/config/dataArea1personal';
import { dataArea2observation } from '@/config/dataArea2observation';
import { dataArea3relations } from '@/config/dataArea3relations';
import { dataArea4activities} from '@/config/dataArea4activities.js';
import { dataArea5playroom } from '@/config/dataArea5playroom.js';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import { useArea1personalStateStore } from '@/stores/area1personalState';
import { useArea2observationStateStore } from '@/stores/area2observationState';
import { useArea3relationsStateStore } from '@/stores/area3relationsState';
import {useArea4activitiesStateStore} from '@/stores/area4activitiesState.js';
import {useArea5playroomStateStore} from '@/stores/area5playroomState.js';
import {usePage} from '@inertiajs/vue3';
import {inject} from 'vue';
import {useArea6audioStateStore} from '@/stores/area6audioState.js';
import {dataArea6audio} from '@/config/dataArea6audio.js';
import moment from 'moment';
//import {dumpSetup} from '@/libraries/utils.js';

//dumpSetup('home', true)
const user = usePage().props.user
const childBirthdate = moment(user?.child_birthdate)
const tg = inject('tg')

const area1 = new AreaObject(user, {
	areaName: AreaObject.AREA1_PERSONAL,
	areaStore: useArea1personalStateStore,
	areaData: dataArea1personal
})
const area4 = new AreaObject(user, {
	areaName: AreaObject.AREA4_ACTIVITIES,
	areaStore: useArea4activitiesStateStore,
	areaData: dataArea4activities,
	dataArea1personal: dataArea1personal,
	dataArea2observation: dataArea2observation,
})
const area3 = new AreaObject(user, {
	areaName: AreaObject.AREA3_RELATIONS,
	areaStore: useArea3relationsStateStore,
	areaData: dataArea3relations,
	dataArea1personal: dataArea1personal,
	dataArea2observation: dataArea2observation,
})
const area2 = new AreaObject(user, {
	areaName: AreaObject.AREA2_OBSERVATION,
	areaStore: useArea2observationStateStore,
	areaData: dataArea2observation,
	dataArea1personal: dataArea1personal
})
const area5 = new AreaObject(user, {
	areaName: AreaObject.AREA5_PLAYROOM,
	areaStore: useArea5playroomStateStore,
	areaData: dataArea5playroom,
	dataArea1personal: dataArea1personal,
	dataArea2observation: dataArea2observation,
})
const area6 = new AreaObject(user, {
	areaName: AreaObject.AREA6_AUDIO,
	areaStore: useArea6audioStateStore,
	areaData: dataArea6audio,
	dataArea1personal: dataArea1personal,
	dataArea2observation: dataArea2observation,
})

const areas = [
	area1,
	area4,
	area3,
	area2,
	area5,
	area6,
	area2, // parole,
	// familyPortrait,
]

// familyPortrait
if(area1.store.modules[0].isCompleted()) {
	areas.push(area1)
}

const bgImgName = (area, index) => {
	let fileName, directory
	switch(index) {
		case 0: fileName = 'p'; break;
		case 1: fileName = 'a'; break;
		case 2: fileName = 'r'; break;
		case 3: fileName = 'o'; break;
		case 4: fileName = 'l'; break;
		case 5: fileName = 'a'; break;
		case 6: fileName = 'parole'; break;
		case 7: fileName = 'familyPortrait'; break;
	}

	//parole
	if(index === 6) {
		if(area2.store.words.length) {
			directory = 'unlocked'
		}
		else {
			directory = 'locked'
		}
	}
	//ritratto di famiglia
	else if(index === 7) {
		if(area1.store.modules[0].isCompleted()) {
			directory = 'unlocked'
		}
		else {
			directory = ''
		}
	}
	else {
		const isAccessible = area.isAccessible(childBirthdate)
		const completionPerc = area.completionPerc()
		if(!isAccessible) {
			directory = 'locked'
		}
		else {
			if(completionPerc === 0) {
				directory = 'unlocked'
			}
			else if(completionPerc === 100) {
				directory = 'completed'
			}
			else {
				directory = 'uncompleted'
			}
		}
	}

	return directory + '/' + fileName
}
const itemClasses = (area, index) => {
	const isAccessible = area.isAccessible(childBirthdate)
	return {
		'unclickable': !isAccessible,
		'clickable': isAccessible,
	}
}

const clickH = (area, index) => {
	if(!area.isAccessible(childBirthdate)) {
		return
	}

	let href = '/' + area.name

	//parole
	if(index === 6) {
		href += '/modules/module1/page1'
	}
	//ritratto di famiglia
	else if(index === 7) {
		href += '?action=modal&id=yourFamilyPortrait'
	}

	window.location.href = href
}

const isAccessible = (area, index) => {
	//parole
	if(index === 6) {
		return !!area2.store.words.length
	}
	//ritratto di famiglia
	else if(index === 7) {
		return true
	}

	return area.isAccessible(childBirthdate)
}
const title = (area, index) => {
	//parole
	if(index === 6) {
		return tg.translate('goToWords')
	}
	//ritratto di famiglia
	else if(index === 7) {
		return tg.translate('yourFamilyPortrait')
	}

	return tg.translate(area.name + '.title')
}
</script>

<template>
	<AppLayout>
		<div class="h-full m-auto top-0 bottom-0"
			style="background-image: url('/img/home-bg.svg')">

			<div class="flex flex-wrap pt-6 pb-12 px-5">
				<template v-for="(area, index) in areas" :key="index">
					<div class="basis-1/2" @click="() => clickH(area, index)">
						<div class="px-9">
							<div class="w-full aspect-square relative"
								:class="itemClasses(area, index)"
								:style="`background-image: url('/img/home/${bgImgName(area, index)}.svg'); ${!isAccessible(area, index)?'filter: grayscale(1) brightness(130%);':''}`">
								<div v-if="!isAccessible(area, index)"
									class="absolute top-0 left-0 w-full aspect-square"
									:style="`background-image: url('/img/home/lock.svg');background-size: 30%;`"
								/>
							</div>
						</div>
						<div class="px-6 pt-2 pb-8 text-[1.35rem] font-bold font-poppins text-black text-center" :style="`${!isAccessible(area, index)?'color: lightgray !important':''}`"
							v-html="title(area, index)"
						/>
					</div>
				</template>
			</div>
		</div>
	</AppLayout>
</template>
