import { defineStore } from 'pinia'
import {useRoute} from 'vue-router';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import {ModuleObject} from '@/dataObjects/area6audio/ModuleObject.js';

const id = 'area6audioState'
export const useArea6audioStateStore = defineStore(id, {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			modules: [],
			questions: [],
			modulesInitialized: false
		}
	},
	/*VARIABILI CALCOLATE*/
	// since we rely on `this`, we cannot use an arrow function
	getters: {
		getSession(state) {
			return state.browserSessionId
		},
	},
	/*FUNZIONI*/
	// since we rely on `this`, we cannot use an arrow function
	actions: {
		/**
		 *
		 * @param dataModules {Array}
		 * @param dbAnswers {Array}
		 * @param dbTimeuseAnswers {Array}
		 * @param dbWordAnswers {Array}
		 */
		create(dataModules, dbAnswers, dbTimeuseAnswers, dbWordAnswers) {
			console.debug(id, 'create')

			//once only
			if(this.modulesInitialized) {
				console.debug(id, 'create skip', this.modules.length)
				return
			}

			dataModules.forEach((module, index) => {
				//create objects
				const newModule = new ModuleObject(module, {
					index: index,
					isFirst: index === 0,
					isLast: index === dataModules.length - 1,
					areaStoreFunction: useArea6audioStateStore,
					areaId: "area6audio_module",
					areaName: AreaObject.AREA6_AUDIO
				})
				this.modules.push(newModule)

				//tutte le domande vanno in this.questions
				newModule.questions.forEach(question => this.questions.push(question))
			})

			this.hydrate(dbAnswers)

			this.modulesInitialized = true
		},

		/**
		 * @param dbAnswers {Array}
		 */
		hydrate(dbAnswers) {
			dbAnswers.forEach(dbAnswer => {
				try {
					this.questions
					//se non trova la domanda deve andare in errore
					.find(question => question.id === dbAnswer.total_question_id)
					.hydrate(dbAnswer)
				}
				catch(e) {
					console.error('no question', dbAnswer.total_question_id, e)
				}
			})
		},

		getModule(index) {
			return this.modules.find(module => module.index === index)
		},

		/**
		 * @returns {?ModuleObject}
		 */
		currentModule() {
			const currentRoute = useRoute()
			//module0_page1 >> module0
			const moduleId = currentRoute.name.replace(/_.+$/, '')

			return this.modules.find(module => module.id === moduleId)
		},
	},
	persist: true
})
