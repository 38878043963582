<script	setup>
import Modal from '@/Pages/sharedComponents/modal.vue';

const props = defineProps({
})
const id = 'setLanguage'
</script>

<template>
	<Modal :id="id">
		<div class="text-3xl font-poppins font-bold text-white text-center">
			Scegli la lingua
		</div>

		<form action="/setLanguage">
			<div class="flex justify-center items-center py-8">
				<div class="flex-none ">
					<select name="language"
						class="text-blue text-xl rounded-full block w-full px-3 py-1 border-0">
						<option value="">Seleziona</option>
						<!-- TODO DISABLED AS REQUESTED BY GUARINI ON 04/10/2024 and ON 24/10/2024 -->
						<option value="it-IT" :selected="$page.props.user?.lang === 'it-IT'">Italiano</option>
<!--						<option value="en-US" :selected="$page.props.user?.lang === 'en-US'">English </option>
						<option value="fr-FR" :selected="$page.props.user?.lang === 'fr-FR'">Francais</option>-->
					</select>
				</div>
			</div>
			<button class="btn flex-grow py-2 px-8 text-2xl font-bold bg-white text-blue">
				Conferma
			</button>
		</form>
	</Modal>
</template>
