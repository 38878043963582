import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import {ALWAYS_OPEN, UNLIMITED_OPENING_MONTHS} from '@/config/generalConfig.js';

const defaultModule = (area, index, tags, accessibilityMonths0=30) => {
	return {
		id: module = `${area}.module${index}`,
		accessibilityMonths0: accessibilityMonths0,
		accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
		accessibilityDays0: 0,
		accessibilityDays1: 0,
		title: `${module}.title`,
		pages: [
			{
				id: page = `${module}.page1`,
				title: `${page}.title`,
				text1: `${page}.text1`,
				questions: [],
				hasModalEnd: false,
			},
		],
		tags: tags,
	}
}
let area, module, page, index = 0
export const dataArea5playroom = {
	id: area = AreaObject.AREA5_PLAYROOM,
	title: `${area}.title`,
	modules: [
		// 1 che belli i puzzle
		defaultModule(area, ++index, [
			'language',
			'attention',
			'movement',
		], 24),
		// 2 alleniamo la memoria
		defaultModule(area, ++index, [
			'language',
			'memory',
		]),
		// 3 ricordi in famiglia
		defaultModule(area, ++index, [
			'language',
			'memory',
			'emotions',
		]),
		// 4 baule delle esperienze
		defaultModule(area, ++index, [
			'language',
			'memory',
			'emotions',
		]),
		// 5 l'arca di noè
		defaultModule(area, ++index, [
			'language',
			'movement',
			'emotions',
		], 24),
		// 6 la pesca delle parole
		{
			id: module = `${area}.module${++index}`,
			accessibilityMonths0: 24,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
			],
			tags: [
				'language',
			],
		},
		// 7 strega comanda color
		{
			id: module = `${area}.module${++index}`,
			accessibilityMonths0: 24,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
			],
			tags: [
				'language',
				'movement',
			],
		},
		// 8 colpisci i mostriciattoli
		{
			id: module = `${area}.module${++index}`,
			title: `${module}.title`,
			accessibilityMonths0: 24,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
			],
			tags: [
				'attention',
				'movement',
			],
		},
		// 9 ascolta la parola e salta
		defaultModule(area, ++index, [
            'language',
            'attention',
            'movement',
		]),
		// 10 lettura in dialogo
		defaultModule(area, ++index, [
            'language',
            'emotions',
		], 24),
		// 11 recitAZIONE
		defaultModule(area, ++index, [
            'language',
            'movement',
            'emotions',
		]),
		// 12 il cesto magico
		defaultModule(area, ++index, [
            'language',
            'attention',
            'movement',
            'memory',
		], 24),
		// 13 oggi musica
		defaultModule(area, ++index, [
            'emotions',
            'attention',
            'movement',
            'memory',
		], 24),
		// 14 dove mi sono nascosto
		defaultModule(area, ++index, [
            'attention',
            'memory',
		]),
		// 15 faccio io
		defaultModule(area, ++index, [
            'attention',
            'memory',
            'emotions',
		]),
		// 16 i palloncini emozionati
		defaultModule(area, ++index, [
            'memory',
            'emotions',
            'movement',
		]),
	]
}
