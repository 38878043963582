<script setup>
import moduleListItem from '@/Pages/area1personal/moduleListItem.vue';
import {compClass, timeout} from '@/libraries/utils.js'
import {getCurrentInstance, onMounted, ref} from 'vue';
import CustomScrollbar from 'custom-vue-scrollbar'
import 'custom-vue-scrollbar/dist/style.css'
import $ from 'jquery'
import {useArea1personalStateStore} from '@/stores/area1personalState.js';
import MicroModal from 'micromodal';
import {riveMainSceneMap} from '@/dataObjects/riveMainSceneMap.js';
import ModalYourFamilyPortrait from '@/Pages/area1personal/modalYourFamilyPortrait.vue';
import {useMainStateStore} from '@/stores/mainState.js';
import ModalFamilyWelcome from './modalFamilyWelcome.vue';
import {usePage} from "@inertiajs/vue3";
import { dataArea1personal } from '@/config/dataArea1personal';
import { AreaObject } from '@/dataObjects/frameworkObjects/AreaObject';

const instance = getCurrentInstance()

const areaStore = ref({})

function _getArea1personal() {
    return new AreaObject(usePage().props.user, {
        areaName: AreaObject.AREA1_PERSONAL,
        areaStore: useArea1personalStateStore,
        areaData: dataArea1personal
    })
}

const firstModuleIsCompleted = ref(false)

onMounted(async ()=>{
	const safetyOffset = 50
	$('.scrollbar__scroller').height(window.innerHeight - safetyOffset)

    areaStore.value = _getArea1personal()
    firstModuleIsCompleted.value = areaStore.value.modules[0]?.isCompleted()
})

const modalYourFamilyPortrait = ref(null)

const openYourFamilyPortraitModal = () => {

    if(!isFamilyReady.value){
        console.debug("[Area1Personal modulesList] Family is not ready yet :)")
        return
    }

	MicroModal.init()

	MicroModal.show('modal-yourFamilyPortrait', {
		onShow: () => {
			if(!modalYourFamilyPortrait.value) {
				return
			}
			modalYourFamilyPortrait.value.playAnimation()
		},
		onClose: () => {
			if(!modalYourFamilyPortrait.value) {
				return
			}
			modalYourFamilyPortrait.value.pauseAnimation()
		}
	})
}

const showFakeOverlay = ref(false)
const isFamilyReady = ref(false)
const shouldOpenWhenReady = ref(false)

async function modalYourFamilyPortraitReady(){

    await timeout(2000) // another Martellata

    console.debug("[Area1Personal modulesList] POPULATING YOUR FAMILY PORTRAIT")

    modalYourFamilyPortrait.value.playAnimation()

    //aggiungiamo cose, cambiamo colori, aggiungo tutto
    riveMainSceneMap.forEach(entry => {
        if(!entry.questionId) {
            return
        }

        const question = areaStore.value.questions.find(question => question.id === entry.questionId)
        if(!question?.answerValue) {
            console.warn(entry.questionId, 'no.answerValue')
            return
        }

        // sviluppo non necessario (forse), mostro l'elemento della famiglia solo se showOnCompletedPageId
        //ovvero in base ad un'altra pagina completata (es. gli elementi della famiglia)
            // if(entry.showOnCompletedPageId) {
            // 	let maybeCompletedPage
            // 	areaStore.value.modules.find(module => {
            // 		maybeCompletedPage = module.pages.find(page => page.id === entry.showOnCompletedPageId)
            // 		return !!maybeCompletedPage
            // 	})
            // 	if(!maybeCompletedPage.isCompleted()) {
            // 		return
            // 	}
            // }

        //rispondo alla domanda che fornisce l'elemento da aggiungere a rive, ma solo a pagina completata ho il
        //popup che mi dice "bravo, hai aggiunto l'elemento!", quindi anche qui devo mantenere il comportamento
        if(!question.parent.isCompleted()) {
            modalYourFamilyPortrait.value.number(entry.inputId, 0)
            return
        }

        //adatto il valore della risposta
        const value = entry.value(question.answerValue)
        console.debug(entry.inputId, value)

        if(value && value !== -1) {
            modalYourFamilyPortrait.value.number(entry.inputId, value)
        } else {
            modalYourFamilyPortrait.value.number(entry.inputId, 0)
        }

        // if(useMainStateStore().environment === 'local') {
        // 	setTimeout(() => modalYourFamilyPortrait.value.pauseAnimation(), 0)
        // }

        // modalYourFamilyPortrait.value.pauseAnimation()

        // modalYourFamilyPortrait.value.playAnimation()

    })

    modalYourFamilyPortrait.value.playAnimation()

    isFamilyReady.value = true

    if(shouldOpenWhenReady.value){
        console.debug("[Area1Personal modulesList] AUTO-opening the family modal...")
        showFakeOverlay.value = false
        shouldOpenWhenReady.value = false
        openYourFamilyPortraitModal()
    }
}

onMounted(() => {
    console.debug("ONMOUNTED")

    // this opens the modal when coming from the homepage shortcut
	const usp = new URLSearchParams(location.search)
	console.debug(usp)
	if(usp.get('action') === 'modal' && usp.get('id') === 'yourFamilyPortrait') {
        console.debug("[Area1Personal modulesList] set AUTO-opening of the family modal, because you're coming from the homepage shortcut!")
        showFakeOverlay.value = true
		shouldOpenWhenReady.value = true
	}

    // force update
    firstModuleIsCompleted.value = areaStore.value.modules[0]?.isCompleted()

    if(!firstModuleIsCompleted.value && (localStorage.getItem('modal-familyWelcome_dontShowAnymore_' + usePage().props.user?.id) !== '1')) {

        MicroModal.show('modal-familyWelcome')
    }

})
</script>

<template>
	<div v-if="firstModuleIsCompleted"
		class="py-4 px-8 bg-white">
		<div class="flex py-6 rounded-3xl" :class="{'bg-blue': isFamilyReady, 'bg-gray': !isFamilyReady, 'clickable': isFamilyReady}"
			@click="openYourFamilyPortraitModal">
			<div class="pl-8 font-poppins font-opensans font-bold tracking-wide text-white">
				{{ isFamilyReady ? $tg('yourFamilyPortrait') : $tg('loading')}}
			</div>
			<div class="basis-auto px-2">
				<div class="relative w-28 h-16">
					<svg width="94" height="64" viewBox="0 0 94 64" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M34.1349 23.9645C27.7698 23.9645 22.6097 18.8044 22.6097 12.4393C22.6097 6.07406 27.7698 0.914062 34.1349 0.914062C40.5001 0.914062 45.6602 6.07406 45.6602 12.4393C45.6602 18.8044 40.5001 23.9645 34.1349 23.9645Z" fill="white"/>
						<path d="M13.0783 23.0504C6.71311 23.0504 1.5531 17.8904 1.5531 11.5252C1.5531 5.15999 6.71311 0 13.0783 0C19.4435 0 24.6035 5.15999 24.6035 11.5252C24.6035 17.8904 19.4435 23.0504 13.0783 23.0504Z" fill="white"/>
						<path d="M32.2444 22.9958C27.2405 22.9958 23.1841 18.9394 23.1841 13.9355C23.1841 8.93166 27.2405 4.87524 32.2444 4.87524C37.2483 4.87524 41.3047 8.93166 41.3047 13.9355C41.3047 18.9394 37.2483 22.9958 32.2444 22.9958Z" fill="black"/>
						<path d="M22.2344 36.1394C17.7599 36.1394 13.6072 33.6331 11.6582 29.751L15.6645 27.7383C16.8587 30.1172 19.4351 31.6523 22.2312 31.6523C25.4126 31.6523 28.2724 29.6523 29.1832 26.7893L33.457 28.1492C31.938 32.9261 27.4253 36.1362 22.2312 36.1362L22.2344 36.1394Z" fill="black"/>
						<path d="M9.47486 22.9958C4.47099 22.9958 0.414564 18.9394 0.414564 13.9355C0.414564 8.93166 4.47099 4.87524 9.47486 4.87524C14.4787 4.87524 18.5352 8.93166 18.5352 13.9355C18.5352 18.9394 14.4787 22.9958 9.47486 22.9958Z" fill="black"/>
						<path d="M83.0048 52.8432C77.203 52.8432 72.4998 48.14 72.4998 42.3382C72.4998 36.5365 77.203 31.8333 83.0048 31.8333C88.8065 31.8333 93.5098 36.5365 93.5098 42.3382C93.5098 48.14 88.8065 52.8432 83.0048 52.8432Z" fill="white"/>
						<path d="M63.8124 52.01C58.0106 52.01 53.3074 47.3067 53.3074 41.505C53.3074 35.7032 58.0106 31 63.8124 31C69.6141 31 74.3174 35.7032 74.3174 41.505C74.3174 47.3067 69.6141 52.01 63.8124 52.01Z" fill="white"/>
						<path d="M80.0122 49.7634C75.4513 49.7634 71.754 46.066 71.754 41.5051C71.754 36.9442 75.4513 33.2468 80.0122 33.2468C84.5732 33.2468 88.2705 36.9442 88.2705 41.5051C88.2705 46.066 84.5732 49.7634 80.0122 49.7634Z" fill="black"/>
						<path d="M72.1585 63.9401C68.0801 63.9401 64.295 61.6557 62.5185 58.1173L66.1701 56.2827C67.2586 58.4511 69.607 59.8502 72.1556 59.8502C75.0554 59.8502 77.662 58.0273 78.4922 55.4177L82.3877 56.6572C81.0031 61.0113 76.8899 63.9372 72.1556 63.9372L72.1585 63.9401Z" fill="black"/>
						<path d="M59.2583 49.7634C54.6974 49.7634 51 46.066 51 41.5051C51 36.9442 54.6974 33.2468 59.2583 33.2468C63.8192 33.2468 67.5166 36.9442 67.5166 41.5051C67.5166 46.066 63.8192 49.7634 59.2583 49.7634Z" fill="black"/>
					</svg>
				</div>
			</div>
		</div>

		<ModalYourFamilyPortrait ref="modalYourFamilyPortrait" @ready="modalYourFamilyPortraitReady"/>
	</div>

  <ModalFamilyWelcome />
  <div v-show="showFakeOverlay" class="fake__modal__overlay" />

	<CustomScrollbar :thumb-max-size="500">
		<div class="pt-4 pl-2 pr-8 h-full flex flex-col bg-white" :class="compClass(instance)">
			<div class="pb-14">
				<div class="basis-full">
					<div v-for="(module, mIndex) in areaStore.modules" :key="mIndex" class="mb-8">
						<moduleListItem :module="module" />
					</div>
				</div>
			</div>
		</div>
	</CustomScrollbar>
</template>

<style>
.fake__modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0,0,0,0.6);
    z-index: 100;
}
</style>
