
//da bcb
export const pushState = (url, data = {}) => {
	if(location.href === url) {
		console.debug('pushState skip', url)
		return
	}

	let inertiaState = window.history.state || {}
	//console.log('pushState ', inertiaState)

	const _data = Object.assign({}, inertiaState, data, {
		url: url
	})
	//console.log('pushState', url, inertiaState, _data)

	window.history.pushState(_data, '', url)
}
//da bcb
export const replaceState = (url, data = {}) => {
	if(location.href === url) {
		console.debug('replaceState skip', url)
		return
	}

	let inertiaState = window.history.state || {}
	//console.log('replaceState ', inertiaState)

	const _data = Object.assign({}, inertiaState, data, {
		url: url
	})
	//console.log('replaceState', url, inertiaState, _data)

	window.history.replaceState(_data, '', url)
}

/**
 * poll1_part2_quest3 > poll1_part2_quest2
 * @param id {string}
 * @return {string}
 */
export const getPrevId = id => {
	return id.replace(
		/(.+?)(\d+)$/,
		'$1' + (getNumberFromId(id) - 1)
	);
}

/**
 * poll1_part2_quest3 > poll1_part2_quest4
 * @param id {string}
 * @return {string}
 */
export const getNextId = id => {
	return id.replace(
		/(.+?)(\d+)$/,
		'$1' + (getNumberFromId(id) + 1)
	);
}

/**
 * box1_file1_exer13 >> 13
 * @param id {string}
 * @param keepStringFormat {boolean}
 * @return {number|string}
 */
export const getNumberFromId = (id, keepStringFormat = false) => {
	let val = id.replace(/.+?(\d+)$/, '$1');
	//maybe convert into number
	return keepStringFormat ? val : +val
}

/**
 * @param url string 'http://a.com'
 * @return {string}
 */
export const getIdFromURL = (url = '') => {
	let id,
		location = url ? new URL(url) : location;

	if(location.search) {
		id = new URLSearchParams(location.search).get('id');
	}

	return id
}

/**
 * box1_file1_exer1 > box1_file1
 * poll1 > .
 * home > .
 */
export const getParentIdFromId = id => {
	return id.replace(/_?[a-z]+\d+$/, '') || '.';
}


/** box1_file1_exer1 > exer
 *
 * box1_files > files
 */
export const getFieldFromId = id => {
	if(id === 'home') {
		return 'home';
	}
	let ret = /([a-z]+)\d*$/.exec(id);

	if(!ret) {
		console.error(id, ret);
		return '';
	}

	return ret[1];
}

/**
 * @param id {string} poll1_part2_quest3
 * @param numberValue {boolean}
 * @return {array}
 * [{
 *   value: 'poll1',
 *   field: 'poll'
 * },{
 *   value: 'part2',
 *   field: 'part'
 * },{
 *   value: 'quest3',
 *   field: 'quest'
 * }]
 */
export const getEntriesFromId = (id, numberValue = false) => {
	if(!id) {
		console.warn('no id', id);
		return [];
	}

	const sep = '_';
	return id.split(sep).map(_id => {
		return {
			field: getFieldFromId(_id),
			value: numberValue ? getNumberFromId(_id) : _id,
		}
	});
}

/**string ids order:
 *  poll1_part2_quest1
 *  poll1_part2_quest10
 *  poll1_part2_quest2
 *  poll1_part2_quest3..
 *
 *  'array' puo essere un array di oggetti o l'oggetto padre che contiene la lista,
 *  nell'esempio sarebbe l'oggetto poll1_part2_quests
 *
 * @param array {[object]|object}
 * @param key {string}
 * @return {[object]}     1,2,3,10
 */
export const sortByKey = (array, key = 'id') => {
	if(!array) {
		return []
	}

	if( !(array instanceof Array) ) {
		array = Object.values(array)
	}

	return array.sort((a, b) => {
		return getNumberFromId(a[key]) - getNumberFromId(b[key]);
	})
}

/**
 * todo isDoubleClick
 * https://stackoverflow.com/questions/5497073/
 * The order of events for a dblclick is:
 *  mousedown
 *  mouseup
 *  click
 *  mousedown
 *  mouseup
 *  click
 *  dblclick
 *
 *  quindi dblclick.preventDefault è inutile
 *
 * @param event
 * @param timeGap
 * @return {boolean}
 * /
export const isDoubleClick = (event, timeGap = 0) => {
	let ret = false
	event = event || event;

	if(!event) {
		console.warn('no event', event)
		return false
	}

	if(!lastClickEvent) {
		ret = false
	}
	else {
		timeGap = timeGap || 500;

		//detail indicates the current click count
		if(event.detail > 1) {
			ret = true
		}
		//too early click, on same elem > dblclick
		else if(
			lastClickEvent.target === event.target &&
			(event.timeStamp - lastClickEvent.timeStamp) < timeGap
		) {
			ret = true
		}
	}

	//save this event for next call
	lastClickEvent = event

	return ret
}

//not used
export const maybePreventDoubleClick = event => {
	event = event || event;
	if(isDoubleClick(event)) {
		event.preventDefault()
	}
}/**/
