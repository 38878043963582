<script	setup>
import Modal from '@/Pages/sharedComponents/modal.vue';

const props = defineProps({
	accessibilityMonths0: Number
})
const id = 'modelIsNotAccessible'
</script>

<template>
	<Modal :id="id">
		<article>
			{{$tg('area2observation.modalNotAccessible')}} <b>{{accessibilityMonths0}} {{$tg('months')}}</b>
		</article>

		<div class="btn w-2/3 mt-8 bg-white text-blue"
			data-micromodal-close aria-label="Close modal">
			{{ $tg('continue')}}
		</div>
	</Modal>
</template>
