import enLocale from '~/localization/en.json'
import itLocale from '~/localization/it.json'

export const EN = "en-US"
export const IT = "it-IT"

const _locales = {};
_locales[''+EN] = enLocale
_locales[''+IT] = itLocale

export const locales = _locales
