import { BaseModuleObject } from "../frameworkObjects/BaseModuleObject";
import { PageObject } from "./PageObject";
import moment from 'moment';
import { useNavigationStore } from "@/stores/navigation";
import { AreaObject } from "../frameworkObjects/AreaObject";
import { dataArea2observation } from "@/config/dataArea2observation";
import { useArea2observationStateStore } from "@/stores/area2observationState";
import { ModuleTypes } from "../frameworkObjects/ModuleTypes";
import { usePage } from "@inertiajs/vue3";
import { ALWAYS_OPEN } from "@/config/generalConfig";

export class ModuleObject extends BaseModuleObject {

    _initializePages(moduleData, props) {
        if(this.isInitialized){
            return
        }
        this.pages = moduleData.pages?.map((page, index) => {
            return new PageObject(page, this, {
                index: index,
                isFirst: index === 0,
                isLast: index === moduleData.pages.length - 1,
                areaStoreFunction: props.areaStoreFunction,
                areaName: this.areaName,
                answers: props.answers,
                timeuseAnswers: props.timeuseAnswers,
                wordAnswers: props.wordAnswers
            }) || []
        })

	    this.isInitialized = true
    }

	// /**
	//  * copiato da TimeuseModuleObject
	//  * accessibile se il bimbo ha un numero di mesi pari o piu di accessibilityMonths0 e meno mesi di accessibilityMonths1
	//  * @param childBirthdate {Moment}
	//  * @returns {boolean}
	//  */
	// isAccessible(childBirthdate) {
	// 	if(!childBirthdate) {
	// 		console.error('no childBirthdate', this)
	// 		return false;
	// 	}

    //     // SE I QUESTIONARI NON SONO COMPILATI NON PUOI APRIRE REPORT!
    //     // TODO se mai cambieremo l'ordine questo ci darà fastidio
    //     // Sviluppo del linguaggio 24 mesi
    //     // if(this.index==1){
    //     //     if(!(this._isBehaviorsCompleted(2) && this._isPhrasesCompleted(3) && this._isAgesAndStagesCompleted(4))){
    //     //         return false
    //     //     }
    //     // }
    //     // Sviluppo del linguaggio 30 mesi
    //     if(this.index==2){
    //         if(!(this._isBehaviorsCompleted(6) && this._isPhrasesCompleted(7) && this._isAgesAndStagesCompleted(8))){
    //             return false
    //         }
    //     }
    //     // Sviluppo del linguaggio 36 mesi
    //     if(this.index==3){
    //         if(!(this._isBehaviorsCompleted(10) && this._isPhrasesCompleted(11) && this._isAgesAndStagesCompleted(12))){
    //             return false
    //         }
    //     }
    //     // La tua giornata 24 mesi
    //     if(this.index==5){
    //         if(!(this._isTimeuseCompleted(5))){
    //             return false
    //         }
    //     }
    //     // La tua giornata 30 mesi
    //     if(this.index==6){
    //         if(!(this._isTimeuseCompleted(9))){
    //             return false
    //         }
    //     }
    //     // La tua giornata 36 mesi
    //     if(this.index==7){
    //         if(!(this._isTimeuseCompleted(13))){
    //             return false
    //         }
    //     }

	// 	const accessibility = this._getAccessibilityDates(childBirthdate)

    //     const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()

	// 	return nowCalc.isSameOrAfter(accessibility.date0) && nowCalc.isBefore(accessibility.date1)
	// }

    isAccessible() {
        // RICHIESTO DA ANNALISA NEL DOCUMENTO Odg_riunioniindici17ottobre.docx
        // I REPORT VENGONO RESI ACCESSIBILI SOLO SE I QUESTIONARI CORRISPONDENTI SONO COMPILATI

        // TODO se mai cambieremo l'ordine, questa gestione ci darà fastidio!

        if(this.accessibilityMonths0 == ALWAYS_OPEN){
            return true
        }

        // # Sviluppo del linguaggio 24 mesi
        if(this.index==1){
            // questo è disabilitato sempre, su loro richiesta.
            return false
            // return (this._isBehaviorsCompleted(2) && this._isPhrasesCompleted(3) && this._isAgesAndStagesCompleted(4))
        }
        // # Sviluppo del linguaggio 30 mesi
        if(this.index==2){
            return (this._isBehaviorsCompleted(6) && this._isPhrasesCompleted(7) && this._isAgesAndStagesCompleted(8))
        }
        // # Sviluppo del linguaggio 36 mesi
        if(this.index==3){
            return (this._isBehaviorsCompleted(10) && this._isPhrasesCompleted(11) && this._isAgesAndStagesCompleted(12))
        }
        // # La tua giornata 24 mesi
        if(this.index==5){
            return (this._isTimeuseCompleted(5))
        }
        // # La tua giornata 30 mesi
        if(this.index==6){
            return (this._isTimeuseCompleted(9))
        }
        // # La tua giornata 36 mesi
        if(this.index==7){
            return (this._isTimeuseCompleted(13))
        }

        return false
    }

    isNotLongerAccessible(childBirthdate){
        if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}
        if(this.isAccessible()){
            return false
        } else {

		    const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()
            // # Sviluppo del linguaggio 24 mesi
            // if(this.index==1){
            //     // questo è disabilitato sempre, su loro richiesta.
            //     return false
            // }
            // # Sviluppo del linguaggio 30 mesi (chiusura questionario a 30mm30gg)
            if(this.index==2){
                this.accessibilityMonths1 = 31
                let lastQuestionnaireDate = childBirthdate?.clone().add(31, 'months').startOf('day')
                return nowCalc.isSameOrAfter(lastQuestionnaireDate)
            }
            // # Sviluppo del linguaggio 36 mesi (chiusura questionario a 36mm30gg)
            if(this.index==3){
                this.accessibilityMonths1 = 37
                let lastQuestionnaireDate = childBirthdate?.clone().add(37, 'months').startOf('day')
                return nowCalc.isSameOrAfter(lastQuestionnaireDate)
            }
            // # La tua giornata 24 mesi (chiusura questionario a 24mm30gg)
            if(this.index==5){
                this.accessibilityMonths1 = 25
                let lastQuestionnaireDate = childBirthdate?.clone().add(25, 'months').startOf('day')
                return nowCalc.isSameOrAfter(lastQuestionnaireDate)
            }
            // # La tua giornata 30 mesi (chiusura questionario a 30mm30gg)
            if(this.index==6){
                this.accessibilityMonths1 = 31
                let lastQuestionnaireDate = childBirthdate?.clone().add(31, 'months').startOf('day')
                return nowCalc.isSameOrAfter(lastQuestionnaireDate)
            }
            // # La tua giornata 36 mesi (chiusura questionario a 36mm30gg)
            if(this.index==7){
                this.accessibilityMonths1 = 37
                let lastQuestionnaireDate = childBirthdate?.clone().add(37, 'months').startOf('day')
                return nowCalc.isSameOrAfter(lastQuestionnaireDate)
            }
        }
        return false
    }

    isCompleted() {
        // reports are never "completed", but we want the icon to be full
        return true
    }

    completionPerc() {
        if(this.isAccessible()){
            return 100
        }
        return 0
    }

    _isBehaviorsCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_BEHAVIORS &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("Behaviors", moduleNum, "completed")
			return true
		}
        console.debug("Behaviors", moduleNum, "NOT COMPLETED")
		return false
	}

	_isPhrasesCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_PHRASES &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("Phrases", moduleNum, "completed")
			return true
		}
        console.debug("Phrases", moduleNum, "NOT COMPLETED")
		return false
	}

    _isAgesAndStagesCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_AGES_AND_STAGES &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("AgesAndStages", moduleNum, "completed")
			return true
		}
        console.debug("AgesAndStages", moduleNum, "NOT COMPLETED")
		return false
	}

    _isTimeuseCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_TIMEUSE &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("Timeuse", moduleNum, "completed")
			return true
		}
        console.debug("Timeuse", moduleNum, "NOT COMPLETED")
		return false
	}

    _getArea2observation() {
        if(this.area2observation){
            return
        }
		this.area2observation = new AreaObject(usePage().props.user, {
			areaName: AreaObject.AREA2_OBSERVATION,
			areaStore: useArea2observationStateStore,
			areaData: dataArea2observation
		})
	}
}
