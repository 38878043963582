import { defineStore } from 'pinia'
import {ModuleObject} from '@/dataObjects/area1personal/ModuleObject.js';
import {unref} from 'vue';
import {useRoute} from 'vue-router';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';

const id = 'area1personalState'
export const useArea1personalStateStore = defineStore(id, {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			modules: [],
			questions: [],
			modulesInitialized: false,
			/*permette di togliere z-10 all'header e risolvere i soliti problemi di z-index*/
			isVideoLightboxVisible: null,
		}
	},
	/*VARIABILI CALCOLATE*/
	// since we rely on `this`, we cannot use an arrow function
	getters: {
		getSession(state) {
			return state.browserSessionId
		},
	},
	/*FUNZIONI*/
	// since we rely on `this`, we cannot use an arrow function
	actions: {
		/**
		 *
		 * @param dataModules {Array}
		 * @param dbAnswers {Array}
		 */
		create(dataModules, dbAnswers) {
			console.debug(id, 'create', dbAnswers.length)

			//once only
			if(this.modulesInitialized) {
				return
			}

			dataModules.forEach((module, index) => {
				//create objects
				const newModule = new ModuleObject(module, {
					index: index,
					isFirst: index === 0,
					isLast: index === dataModules.length - 1,
					areaStoreFunction: useArea1personalStateStore,
					areaId: "area1personal_module",
					areaName: AreaObject.AREA1_PERSONAL
				})
				this.modules.push(newModule)

				//tutte le domande vanno in this.questions
				newModule.questions.forEach(question => this.questions.push(question))
			})

			this.hydrate(dbAnswers)

			this.modulesInitialized = true
		},
		/**
		 * @param dbAnswers {Array}
		 */
		hydrate(dbAnswers) {
			dbAnswers.forEach(dbAnswer => {
				try {
					this.questions
						//se non trova la domanda deve andare in errore
						.find(question => question.id === dbAnswer.total_question_id)
						.hydrate(dbAnswer)
				}
				catch(e) {
					console.error('no question', dbAnswer.total_question_id, e)
				}
			})
		},
		getModule(index) {
			return this.modules.find(module => module.index === index)
		},
		/**
		 * @returns {?ModuleObject}
		 */
		currentModule() {
			const currentRoute = useRoute()
			//module0_page1 >> module0
			const moduleId = currentRoute.name.replace(/_.+$/, '')

			return this.modules.find(module => module.id === moduleId)
		},
	},
	persist: true
})
