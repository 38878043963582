import { defineStore } from 'pinia'
import {ModuleTypes} from '@/dataObjects/frameworkObjects/ModuleTypes.js'
import {ModuleObject as ReportModuleObject} from '@/dataObjects/area3relations/ModuleObject.js'
import {unref} from 'vue';
import {useRoute} from 'vue-router';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';

const id = 'area3relationsState'
export const useArea3relationsStateStore = defineStore(id, {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			modules: [],
			words: [],
			questions: [],
			modulesInitialized: false
		}
	},
	/*VARIABILI CALCOLATE*/
	// since we rely on `this`, we cannot use an arrow function
	getters: {
		getSession(state) {
			return state.browserSessionId
		},
	},
	/*FUNZIONI*/
	// since we rely on `this`, we cannot use an arrow function
	actions: {
		/**
		 *
		 * @param dataModules {Array}
		 * @param dbAnswers {Array}
		 * @param dbTimeuseAnswers {Array}
		 * @param dbWordAnswers {Array}
		 */
		create(dataModules, dbAnswers, dbTimeuseAnswers, dbWordAnswers) {
			console.debug(id, 'create')

			//once only
			if(this.modulesInitialized) {
				console.debug(id, 'create skip', this.modules.length)
				return
			}

			dataModules.forEach((module, index) => {
				//create objects
				if ((module.type === ModuleTypes.TYPE_REPORT)){
					const newModule = new ReportModuleObject(module, {
						index: index,
						isFirst: index === 0,
						isLast: index === dataModules.length - 1,
						areaStoreFunction: useArea3relationsStateStore,
						areaId: "area3relations_module",
						areaName: AreaObject.AREA3_RELATIONS,
                        answers: dbAnswers,
                        timeuseAnswers: dbTimeuseAnswers,
                        wordAnswers: dbWordAnswers
					})

					this.modules.push(newModule)
				}
			})

			this.modulesInitialized = true

		},

		getModule(index) {
			return this.modules.find(module => module.index === index)
		},

		/**
		 * @returns {?ModuleObject}
		 */
		currentModule() {
			const currentRoute = useRoute()
			//module0_page1 >> module0
			const moduleId = currentRoute.name.replace(/_.+$/, '')

			return this.modules.find(module => module.id === moduleId)
		},
	},
	persist: true
})
