import {i18n} from '@/i18n.js';
import {useMainStateStore} from '@/stores/mainState.js';

const defaultLang = 'it-IT'

export class UserObject {
	constructor() {
		//qui getCurrentInstance puo restituire null, non posso usarlo per avere app.config.globalProperties
		//quindi lo sostituisco con useMainStateStore
		this.mainStore = useMainStateStore()
		this.name = null
		//serve a idratare le domande, uso un'oggetto per accedere alla singola direttamente per chiave
		this.questions = {}
	}

	/**
	 *
	 * @param inertiaPagePropsUser
	 * @param activeArea {String} non usato per ora
	 */
	hydrate(inertiaPagePropsUser, activeArea) {
		if(!this.name) {
			this.name = inertiaPagePropsUser.name
		}
		console.debug('UserObject', 'hydrate', inertiaPagePropsUser.lang)

		i18n.global.locale.value = inertiaPagePropsUser.lang || defaultLang

		if(inertiaPagePropsUser.child_gender) {
			this.mainStore.setTranslateGender(inertiaPagePropsUser.child_gender)
		}
	}
}
