import {BaseQuestionObject} from '@/dataObjects/frameworkObjects/BaseQuestionObject.js';
import {getNextId, getPrevId} from '@/libraries/navUtils.js';
import {saveRemoteAnswers} from '@/libraries/network.js';
import {usePage} from '@inertiajs/vue3';
import {useMainStateStore} from '@/stores/mainState.js';

export class BasePageObject {

	constructor(pageData, parent, props = {}) {
		this.parent = this.module = parent
		this.index = props.index + 1 //index da 1
		this.isFirst = props.isFirst
		this.isLast = props.isLast
		this.treatmentOnly = pageData.treatmentOnly

		this.id = `${this.parent.id}_page${this.index}`
		//perche ho scelto dei moduleData.id (es. 'family') diversi dal canonico 'module1'?
		//anche lang
		this.dataId = pageData.id
		this.title = pageData.title
		this.text1 = pageData.text1
		this.if = pageData.if
		this.hasModalEnd = pageData.hasModalEnd
		this.questionSubtext1 = pageData.questionSubtext1
		this.questionSubtext2 = pageData.questionSubtext2
		this.questionSubtext3 = pageData.questionSubtext3
		this.questionSubtext4 = pageData.questionSubtext4
		this.questionSubtext5 = pageData.questionSubtext5
		this.questionSubtext6 = pageData.questionSubtext6
		this.questionSubtext7 = pageData.questionSubtext7
		this.mainStore = useMainStateStore()
		this.areaStore = props.areaStoreFunction()
		this.areaName = props.areaName

		this._initializeQuestions(pageData, props)

		this.navigationStore = parent.navigationStore
	}

	_initializeQuestions(pageData, props) {
		/**
		 * @type {BaseQuestionObject[]}
		 */
		this.questions = pageData.questions?.map((question, index) => {
			return new BaseQuestionObject(question, this, {
				index: index,
				isFirst: index === 0,
				isLast: index === pageData.questions.length - 1,
			}) || []
		})

		//serve a far arrivare tutte le domande nello store
		//todo this.questions.forEach(question => this.parent.store.questions.push(question))
		this.questions.forEach(question => this.parent.questions.push(question))

		this.isInitialized = true
	}

	/**
	 * una pagina è completa se non ha domande oppure ha le domande sono gia' risposte
	 * @returns {boolean}
	 */
	isCompleted() {
		return !this.questions.length || !this.questions.find(question => {
			//considera solo le risposte salvate nel db remoto
			return !question.isAnswered(true)
		})
	}

	/**
	 * pagina con almeno una risposta non vista
	 * @returns {boolean}
	 */
	isNotSeen() {
		return !!this.questions.find(question => {
			//considera solo le risposte salvate sul db remoto
			return question.isNotSeen(true)
		})
	}

	/**
	 * questa pagina potrebbe non essere accessibile.
	 *
	 * se il risultato di isAccessible e' positivo restituisce se stessa, altrimenti
	 * riprova con la pagina successiva o precedente (in base a direction)
	 * @param direction {String} 'prev' or 'next'
	 * @returns {BasePageObject|undefined}
	 */
	meAccessibleOr(direction) {
		if(!this.isAccessible()) {
			//verifica non passata, provo un'altra pagina
			const page = this.nearPage(direction)

			return page?.meAccessibleOr(direction)
		}

		return this
	}

	/**
	 * pagina successiva o precedente (in base a direction)
	 * @param direction {String} 'prev' or 'next'
	 * @returns {BasePageObject|undefined}
	 */
	nearPage(direction) {
		const pageId = direction === 'next' ? getNextId(this.id) : getPrevId(this.id)
		return this.parent.pages.find(page => page.id === pageId)
	}

	/**
	 * esegue un controllo su questa pagina in base alla condizione data.page.if,
	 */
	isAccessible() {
		if(this.if) {
			for(let name in this.if) {
				if(name === 'operator' || !this.if.hasOwnProperty(name)) {
					continue
				}
				const questionId = name
				/**
				 * @var questionToCheck {BaseQuestionObject}
				 * @var questionValuesToCheck {Array}
				 */
				const questionToCheck = this.areaStore.questions.find(question => {
					return question.id === questionId && question.isAnswered()
				})
				//se la domanda non ha risposta, la pagina non e' accessibile
				if(!questionToCheck) {
					return false
				}

				//la pagina non e' accessibile in base all'operatore (!==, ===) e ai valori di questionValuesToCheck
				const questionValuesToCheck = this.if[questionId]

				//l-operatore di default e' ===
				const operator = this.if.operator || '==='

				if(operator === '===') {
					//se alla domanda NON e' stato risposto con uno dei valori di
					//questionValuesToCheck, la pagina non e' accessibile
					if(!questionValuesToCheck.find(value => questionToCheck.hasThisAnswerValue(value, true))) {
						return false
					}
				}
				else if(operator === '!==') {
					//se alla domanda e' stato risposto con uno dei valori di
					//questionValuesToCheck, la pagina non e' accessibile
					if(questionValuesToCheck.find(value => questionToCheck.hasThisAnswerValue(value, true))) {
						return false
					}
				}
				else {
					console.error('bad operator', questionId, operator, this.if)
					return false
				}
			}
		}
		return true
	}

	disableSomeQuestions() {
		let isPageDirty = false

		this.questions?.forEach(question => {
			if(!question.if || question.isDisabled()) {
				return
			}
			//Luca: perché la gestione è diversa dal disabledIf? Disagio!
			//
			//Andrea: capisco, le 2 modalità partono da elementi diversi (disabledIf usa le altre domande
			//della fratellanza ad ogni setAnsower, mentre if le usa tutte), quindi la parte "esterna" non può
			//che essere diversa. La parte "interna" invece, potrebbe essere uguale, ma if e disabledIf
			// hanno storie diverse, e la gestione dell'operatore è arrivata nella if solo ultimamente
			//e avevo bisogno di una modalita' piu comoda, con variabili da loggare. Inoltre disabledIF
			//e' una funzione dell'ultimo minuto (non so di quale arco temporale).

			//La cosa delicata è lo stato dirty delle domande, che necessita di salvataggio sul db. Per question.if
			//va salvato subito, per question.disabledIf no, perché è fatto al volo mentre l'utente sta rispondendo
			console.debug("question.if", question.if)
			for(let name in question.if) {
				if(name === 'operator' || !question.if.hasOwnProperty(name)) {
					continue
				}
				const questionId = name
				console.debug("questionId", questionId)
				/**
				 * @var questionToCheck {BaseQuestionObject}
				 * @var questionValuesToCheck {Array}
				 */
				const questionToCheck = question.parent.areaStore.questions.find(question => {
					return question.id === questionId && question.isAnswered()
				})

				//se la domanda da controllare non ha risposta, questa domanda non e' disabilitata
				if(!questionToCheck) {
					console.debug("!questionToCheck", questionToCheck)
					continue
				}

				//la domanda e' disabilitata in base all'operatore (!==, ===) e ai valori di questionValuesToCheck
				const questionValuesToCheck = question.if[questionId]

				//l-operatore di default e' ===
				const operator = question.if.operator || '==='

				if(operator === '===') {
					//se alla domanda NON e' stato risposto con uno dei valori di
					//questionValuesToCheck, la domanda e' disabilitata
					if(!questionValuesToCheck.find(value => questionToCheck.hasThisAnswerValue(value, true))) {
						console.debug("question.disable", question.index)
						question.disable()
						isPageDirty = true
					}
				}
				else if(operator === '!==') {
					//se alla domanda e' stato risposto con uno dei valori di
					//questionValuesToCheck, la domanda e' disabilitata
					if(questionValuesToCheck.find(value => questionToCheck.hasThisAnswerValue(value, true))) {
						console.debug("question.disable", question.index)
						question.disable()
						isPageDirty = true
					}
				}
				else {
					console.error('bad operator', questionId, operator, question.if)
					question.disable()
					isPageDirty = true
				}
			}
		})

		return isPageDirty
	}
	/**
	 * @returns {Promise<string>}
	 */
	async goToNearPage(direction) {
		const areaName = this.areaName
		//first or last page, go to area
		if(
 			(direction === 'prev' && this.isFirst) ||
			(direction === 'next' && this.isLast)
		) {
			await this.navigationStore.goToPage(areaName)
		}
		else {
			let page = this.parent.getPageById(
				direction === 'prev' ? getPrevId(this.id) : getNextId(this.id)
			)
			page = page.meAccessibleOr(direction)

			await this.navigationStore.goToPage(page?.id || areaName)
		}
	}

	/**
	 * Salva le eventuali domande zozze
	 * @returns {Promise<void>}
	 */
	async save() {
		//domande con nuova risposta da salvare
		const answersToSave = this.questions.filter(question => question.canBeSavedToDb())

		if(answersToSave.length) {
			try {
				await saveRemoteAnswers(
					answersToSave.map(question => question.answerDbFormat(this))
				)
			} catch(e) {
				console.error('Page.save catch', e);
			}

			//console.log('pageSaved', usePage().props?.user?.achievements.length)

			const moduleDbAnswers = (usePage().props.user.answers || []).filter(answer => {
				return answer.module_id === this.parent.index
			})

			// hydrate.questions
			this.areaStore.hydrate(moduleDbAnswers)
		}
	}

	/**
	 * @param force {boolean} true per il debug
	 */
	setAsSeen(force = false) {
		this.questions.forEach(question => question.setAsSeen(force))
	}

	/**
	 * @param questionSupertype {?string} 'question'|'video'|'game', invece null usa tutte le risposte
	 * @returns {number}
	 */
	score(questionSupertype = null) {
		return (
			questionSupertype ? this.getQuestionsBySupertype(questionSupertype) : this.questions
		).reduce(
			(previousValue, question) => previousValue + (question.answerScore || 0),
			0
		)
	}

	getQuestionsBySupertype(questionSupertype) {
		return this.questions.filter(question => question.supertype === questionSupertype)
	}

	hasVideoQuestions() {
		return !!this.questions.find(question => question.type === BaseQuestionObject.TYPE_VIDEO)
	}

	//debug start
	complete() {
		this.questions.forEach(question => question.complete())
	}
	setAsNotSeen() {
		this.questions.forEach(question => question.setAsNotSeen())
	}
	//debug end
}

