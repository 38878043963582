import { BaseModuleObject } from "../frameworkObjects/BaseModuleObject";
import moment from 'moment';
import { useNavigationStore } from "@/stores/navigation";

export class ModuleObject extends BaseModuleObject {

	/**
	 * copiato da TimeuseModuleObject
	 * accessibile se il bimbo ha un numero di mesi pari o piu di accessibilityMonths0 e meno mesi di accessibilityMonths1
	 * @param childBirthdate {Moment}
	 * @returns {boolean}
	 */
	isAccessible(childBirthdate){
        if(this.isCompleted()){
            return true
        }

		if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}
		if(this.isFirst) {
			return true
		}
		const accessibility = this._getAccessibilityDates(childBirthdate)

		const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()

		return nowCalc.isSameOrAfter(accessibility.date0) && nowCalc.isBefore(accessibility.date1)
	}
}
