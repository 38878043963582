import {TimeusePageObject} from '@/dataObjects/area2observation/timeuse/TimeusePageObject.js';
import {TimeuseQuestionObject} from '@/dataObjects/area2observation/timeuse/TimeuseQuestionObject.js';
import moment from 'moment/moment';
import {TimeuseModuleObject} from '@/dataObjects/area2observation/timeuse/TimeuseModuleObject.js';
import {saveRemoteTimeuseAnswers} from '@/libraries/network.js';
import {usePage} from '@inertiajs/vue3';

export class LoopObject {
	/**
	 * @param pageDataQuestions {[]}
	 * @param parent {TimeusePageObject}
	 * @param props
	 */
	constructor(pageDataQuestions, parent, props = {}) {
		this.parent = this.page = parent
		this.timeuse = this.page.parent
		this.index = props.index //index da 1
		this.id = `${this.parent.id}_loop${this.index}`
		this.isEmpty = true
		/**
		 * @type {[TimeuseQuestionObject]}
		 */
		this.questions = []

		pageDataQuestions?.forEach((question, index) => {
			this.questions.push(
				new TimeuseQuestionObject(question, this, {
					index: index,
					isFirst: index === 0,
					isLast: index === pageDataQuestions.length - 1,
				})
			)
		})

		//tutte le domande sono in module.questions in modo da idratarle in un solo punto,
		//in timeuse.hydrate chiamato in area2observationState.create e page.save
		this.questions.forEach(question => this.timeuse.questions.push(question))
	}

	/**
	 * @returns {moment.Moment|null}
	 */
	datetimeEnd(dbAnswersOnly = false, convert = true) {
		const question = this.questions.find(question => {
			return question.type === TimeuseQuestionObject.TYPE_DATEPICKER && question.isAnswered(dbAnswersOnly)
		})

		return question
			? (convert ? moment(question.answerValue) : question.answerValue)
			: null
	}

	/**
	 * un loop è completo se non ha domande oppure ha le domande sono gia' risposte
	 * @returns {boolean}
	 */
	isCompleted() {
		return !this.questions.length || !this.questions.find(question => {
			//considera solo le risposte salvate nel db remoto
			return !question.isAnswered(true)
		})
	}

	siblings() {
		return this.page.loops.filter(loop => loop.index !== this.index)
	}

	prev() {
		return this.siblings().find(loop => loop.index === this.index - 1)
	}

	next() {
		return this.siblings().find(loop => loop.index === this.index + 1)
	}

	/**
	 * restituisce oggetto con date orario di inizio e di fine
	 * @param dbAnswersOnly
	 * @returns {{start: (moment.Moment|null), end: (moment.Moment|null)}}
	 */
	datetimes(dbAnswersOnly = false) {
		const prev = this.prev()

		return {
			start: prev ? prev.datetimeEnd(dbAnswersOnly) : this.timeuse.datetimeStart,
			end: this.datetimeEnd(dbAnswersOnly)
		}
	}

	/**
	 * vengono considerate solo le pagine con domande, una pagina è completa se ha TUTTE le risposte
	 * @returns {number}
	 */
	completionPerc() {
		return 100 * 4 / 10
	}


}
