import { BaseModuleObject } from "../frameworkObjects/BaseModuleObject";
import moment from 'moment';
import { useNavigationStore } from "@/stores/navigation";
import { AreaObject } from "../frameworkObjects/AreaObject";
import { dataArea2observation } from "@/config/dataArea2observation";
import { useArea2observationStateStore } from "@/stores/area2observationState";
import { ModuleTypes } from "../frameworkObjects/ModuleTypes";
import { usePage } from "@inertiajs/vue3";

export class ModuleObject extends BaseModuleObject {

	/**
	 * accessibile se il bimbo ha un numero di mesi pari o piu di accessibilityMonths0 e meno mesi di accessibilityMonths1
     * MODIFICATO APPOSITAMENTE IN SEGUITO A RICHIESTE DI ANNALISA IN DOCUMENTO Odg_riunioniindici17ottobre.docx
	 * @param childBirthdate {Moment}
	 * @returns {boolean}
	 */
	isAccessible(childBirthdate){
		if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}
		// if(this.isFirst) {
		// 	return true
		// }
		// const accessibility = this._getAccessibilityDates(childBirthdate)

		const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()

        const FIRST_MONTHS_THRESHOLD = 24
        const SECOND_MONTHS_THRESHOLD = 30

        const UNLOCK_FIRST_ACTIVITIES_ANYWAYS_MONTHS_THRESHOLD = 25
        const UNLOCK_SECOND_ACTIVITIES_ANYWAYS_MONTHS_THRESHOLD = 31

        if (this.accessibilityMonths0 >= FIRST_MONTHS_THRESHOLD && this.accessibilityMonths0 < SECOND_MONTHS_THRESHOLD) {
            // "giochi dei 24 mesi"
            if(nowCalc.diff(childBirthdate, 'months') >= UNLOCK_FIRST_ACTIVITIES_ANYWAYS_MONTHS_THRESHOLD){
                // se siamo al 25esimo mese sblocca i giochi dei 24 mesi a prescindere dal completamento dei questionari
                return true
            } else {
                // altrimenti sblocca solo se sono stati completati i questionari corrispondenti
                return (this._isBehaviorsCompleted(2) && this._isPhrasesCompleted(3) && this._isAgesAndStagesCompleted(4))
            }
        }
        else if (this.accessibilityMonths0 >= SECOND_MONTHS_THRESHOLD){
            // "giochi dei 30 mesi"
            // sblocca solo se sono stati completati i questionari corrispondenti
            if(nowCalc.diff(childBirthdate, 'months') >= UNLOCK_SECOND_ACTIVITIES_ANYWAYS_MONTHS_THRESHOLD){
                // se siamo al 31esimo mese sblocca i giochi dei 30 mesi a prescindere dal completamento dei questionari
                return true
            } else {
                return (this._isBehaviorsCompleted(6) && this._isPhrasesCompleted(7) && this._isAgesAndStagesCompleted(8))
            }
        }

        return false

	}

    _isBehaviorsCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_BEHAVIORS &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("Behaviors", moduleNum, "completed")
			return true
		}
        console.debug("Behaviors", moduleNum, "NOT COMPLETED")
		return false
	}

	_isPhrasesCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_PHRASES &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("Phrases", moduleNum, "completed")
			return true
		}
        console.debug("Phrases", moduleNum, "NOT COMPLETED")
		return false
	}

    _isAgesAndStagesCompleted(moduleNum) {
        this._getArea2observation()
		// noinspection RedundantIfStatementJS
		if(this.area2observation.store.modules.find(module => {
			return (
				module.type === ModuleTypes.TYPE_AGES_AND_STAGES &&
				module.index === moduleNum &&
				module.isCompleted()
			)
		})) {
            console.debug("AgesAndStages", moduleNum, "completed")
			return true
		}
        console.debug("AgesAndStages", moduleNum, "NOT COMPLETED")
		return false
	}

    _getArea2observation() {
        if(this.area2observation){
            return
        }
		this.area2observation = new AreaObject(usePage().props.user, {
			areaName: AreaObject.AREA2_OBSERVATION,
			areaStore: useArea2observationStateStore,
			areaData: dataArea2observation
		})
	}
}
