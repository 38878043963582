<script setup>

const props = defineProps({
    text: "",
    class: ""
})

function getLetterStyle(char) {
	if([`'`, `"`].includes(char)) {
		return ''
	}

    let minHeight = -0.2
    let maxHeight = 0.2
    let heightMove = (Math.random() * (maxHeight - minHeight) + minHeight).toFixed(2)
    let minRot = -6.0
    let maxRot = 6.0
    let rotation = (Math.random() * (maxRot - minRot) + minRot).toFixed(2)
    return `bottom: ${heightMove}rem; rotate: ${rotation}deg`
}

const debug = `Cos'altro "vorrebbe  veramente" fare il/la tuo/tua bambin* Paola l'altra settimana?`
</script>

<template>
	<p class="-my-2" :class="props.class">
		<template v-for="(word, i) in text.split(' ')" >
			<div v-if="word" class="inline-block py-2" :key=i>

				<span v-for="(char, index) in word.split('')" :key=index
					class="relative inline-block keep-case" :style=getLetterStyle(char)>
		        {{char}}
				</span>
				<span>&nbsp;</span>

			</div>
		</template>
	</p>
</template>

<style scoped>

</style>

