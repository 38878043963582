<script setup>
import { getCurrentInstance} from 'vue';
import {compClass} from '@/libraries/utils.js';
import { useNavigationStore } from '@/stores/navigation'
import {ModuleObject} from '@/dataObjects/area3relations/ModuleObject.js';
import ArrowRight from '@/Pages/sharedComponents/icons/ArrowRight.vue';
import ProgressCircle from '@/Pages/sharedComponents/icons/ProgressCircle.vue';
import MicroModal from 'micromodal';
import Clock from '@/Pages/sharedComponents/icons/Clock.vue';
import TagsList from '@/Pages/sharedComponents/tagsList.vue';

const navigationStore = useNavigationStore()
// non sono riuscito a importare come variabili la conf di tw
//https://tailwindcss.com/docs/configuration#referencing-in-java-script

const props = defineProps({
	module: ModuleObject,
	childBirthdate: Object,
})
const instance = getCurrentInstance()

const emit = defineEmits(['modelIsNotAccessible'])
//
const progressCircleColor = () => {
	if(!props.module.isAccessible(props.childBirthdate)) {
		return 'blue'
	}
	return 'fuchsia'
}
const classes1 = () => {
	const isAccessible = props.module.isAccessible(props.childBirthdate)
	return {
		'pt-1': isAccessible,
		'pt-2': !isAccessible,
	}
}
const classes2 = () => {
	const isAccessible = props.module.isAccessible(props.childBirthdate)
	return {
		'py-3': isAccessible,
	}
}

const clickH = () => {
	navigationStore.goToPage(props.module.pages[0]?.id)
}

const notAccessible = () => {
	//troppo tardi madame
	if(props.module.isNotLongerAccessible(props.childBirthdate)) {
		MicroModal.show('modal-modelIsNotLongerAccessible')
	}
	else {
		emit('modelIsNotAccessible')
		MicroModal.show('modal-modelIsNotAccessible')
	}
}
</script>
<template>
	<div :class="compClass(instance)" class="flex basis-full">
		<div class="basis-auto px-5" :class="classes1()">
			<div class="" :class="classes2()">
				<ProgressCircle
					:perc="module.completionPerc()"
					:color=progressCircleColor()
					class="w-10 h-10"
				/>
			</div>
		</div>
		<div class="basis-full">
			<div v-if="module.isAccessible(props.childBirthdate)">
				<div class="relative bg-fuchsia text-white rounded-3xl"
					:class="'clickable'"
					@click="clickH">
					<div class="pt-5 px-5 flex justify-between">
						<div class="text-xl font-opensans font-bold">
						</div>
						<div >
							<ArrowRight class="h-6 w-6" :color="'yellow'" />
						</div>
					</div>
					<div class="pt-4 px-5" style="min-height: 9rem;">
						<div class="text-[1.6rem] leading-10 font-semibold"
							v-html="$tg(module.title)"
						/>
						<div class="pt-3">
							<TagsList :tags="module.tags"/>
						</div>
					</div>
					<div class="aspect-[2/1]" />
				</div>
			</div>
			<div v-else
				class="px-7 flex justify-between items-center clickable bg-purplelight text-white rounded-3xl"
				@click="notAccessible">
				<div class="py-4 text-xl font-semibold font-opensans">
					{{$tg(module.title)}} - {{module.accessibilityMonths0}} {{$tg('months')}}
				</div>
				<div>
					<Clock :color="'white'" class="w-7" />
				</div>
			</div>
		</div>
	</div>
</template>
