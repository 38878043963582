<script setup>
import { onMounted } from 'vue';
import $ from 'jquery';
import {usePage} from '@inertiajs/vue3';

const props = defineProps({
	id: String,
	bgColor: {
		type: String,
		default: "blue",
	},
	fgColor: {
		type: String,
		default: "white",
	},
	fullscreen: Boolean,
	enableOverlayClose: {
		type: Boolean,
		default: true
	},
	hasDontShowAnymore: {
		type: Boolean,
		default: false
	},
})

const emits = defineEmits(['close'])

const bgColorStyle = `rgb(var(--color-${props.bgColor}) / 1)`
const fgColorStyle = `rgb(var(--color-${props.fgColor}) / 1)`
const margin = props.fullscreen ? 1.5 : 0
const width = props.fullscreen ? '100%' : 'calc(100% - 3rem)'

onMounted(()=>{
	if(props.enableOverlayClose){
		// assumes that first element is the overlay
		document.getElementById(`modal-${props.id}`).firstChild.setAttribute('data-micromodal-close', '')
	}
})
const saveDontShowAnymore = evt => {
	localStorage.setItem(
		'modal-' + props.id + '_dontShowAnymore_' + usePage().props.user?.id,
		$(evt.target).is(':checked') ? '1' : '0'
	)
}
</script>

<template>
	<teleport to="body">
		<div class="modal micromodal-slide relative z-10" :id="'modal-'+id" :class="'modal-'+id" aria-hidden="true">
			<div class="modal__overlay" tabindex="-1">
				<div class="modal__container relative"
					role="dialog" aria-modal="true" :aria-labelledby="`modal-${id}-title`">

					<div @click="$emit('close')" class="modal__close absolute w-10 h-10 z-10 clickable"
						:class="!fullscreen ? 'right-8 top-2' : 'right-0'"
						data-micromodal-close aria-label="Close modal">
						<div class="relative w-full h-full">
							{{/*aggiungere data-micromodal-close anche al svg, altrimenti non funziona*/''}}
							<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"
								data-micromodal-close>
								<circle cx="17" cy="17" r="17" fill="#F30280"/>
								<path d="M22.3638 27C21.7429 27 21.1572 26.7116 20.7786 26.2195L18.285 22.9784C17.4552 21.8997 15.8137 21.9469 15.0473 23.0715L12.965 26.1264C12.5924 26.673 11.9738 27 11.3123 27H10.1022C8.45074 27 7.51091 25.1117 8.50663 23.7941L12.7489 18.1808C13.292 17.4621 13.2877 16.469 12.7382 15.7551L8.47809 10.2198C7.46589 8.90466 8.40345 7 10.063 7H11.6344C12.2563 7 12.8429 7.2893 13.2214 7.78275L15.6866 10.9966C16.5118 12.0725 18.1459 12.0327 18.9178 10.9179L21.034 7.8615C21.4076 7.32195 22.0221 7 22.6783 7H23.8667C25.5238 7 26.4621 8.89973 25.4549 10.2156L21.2217 15.746C20.6718 16.4645 20.6727 17.4626 21.2239 18.1801L25.5273 23.7815C26.5379 25.0969 25.6001 27 23.9414 27H22.3638Z" fill="white"/>
							</svg>
						</div>
					</div>

					<div class="modal__sub_container rounded-3xl !m-auto"
						:class="!fullscreen && 'p-12'">
						<div class="modal__content text-2xl text-center">
							<slot />

							<label v-if="hasDontShowAnymore" class="flex justify-center items-center pt-6">
								<input type="checkbox" :name="id+'_dontShowAnymore'" class="mr-3"
									@change="saveDontShowAnymore" value="1"
								/>
								<span class="text-xl" style="line-height: 1">{{ $tg('dontShowAnymore') }}</span>
							</label>
						</div>
					</div>

				</div>
			</div>
		</div>
	</teleport>
</template>

<style scoped>
.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0,0,0,0.6);
}
.modal__container {
	max-height: 100vh;
	/*overflow-y: auto; this made some popups pretty horrible */
}
.modal__sub_container {
	margin: v-bind(margin)rem;
	width: v-bind(width);
	background-color: v-bind(bgColorStyle);
}
@media (max-width: 767px) {
	.modal__container {
		width: 100vw;
	}
}
@media (min-width: 768px) {
	.modal__container {
		width: 500px;
	}
}
.modal__content {
	color: v-bind(fgColorStyle);
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes mmfadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}
@keyframes mmslideIn {
	from { transform: translateY(15%); }
	to { transform: translateY(0); }
}
@keyframes mmslideOut {
	from { transform: translateY(0); }
	to { transform: translateY(-10%); }
}
.micromodal-slide {
	display: none;
}
.micromodal-slide.is-open {
	display: block;
}
.micromodal-slide[aria-hidden="false"] .modal__overlay {
	animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}
.micromodal-slide[aria-hidden="false"] .modal__container {
	animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}
.micromodal-slide[aria-hidden="true"] .modal__overlay {
	animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}
.micromodal-slide[aria-hidden="true"] .modal__container {
	animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}
.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
	will-change: transform;
}
</style>
