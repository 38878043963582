<script	setup>
import Modal from '@/Pages/sharedComponents/modal.vue';

const props = defineProps({
	accessibilityMonths1: {
        type: String,
        default: ""
    },
    areaStringWithDot: {
        type: String,
        default: "" // retrocompatibility. DUMB. Also need to specify the dot if present
    }
})
const id = 'modelIsNotLongerAccessible'
</script>

<template>
	<Modal :id="id">
		<article>
			{{$tg(areaStringWithDot+'modalNotLongerAccessible')}} <b v-if='accessibilityMonths1!=""'>{{accessibilityMonths1}} {{$tg('months')}}</b>
		</article>

		<div class="btn w-2/3 mt-8 bg-white text-blue"
			data-micromodal-close aria-label="Close modal">
			{{ $tg('continue')}}
		</div>
	</Modal>
</template>
