import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import {BaseQuestionObject} from '@/dataObjects/frameworkObjects/BaseQuestionObject.js';

let area, module, page
export const dataArea6audio = {
	id: area = AreaObject.AREA6_AUDIO,
	title: `${area}.title`,
	modules: [
		{
			id: module = `${area}.module1`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module2`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module3`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module4`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module5`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module6`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module7`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module8`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module9`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module10`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module11`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module12`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
		{
			id: module = `${area}.module13`,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [
						{
							type: BaseQuestionObject.TYPE_LIKERT,
							text: `didYouLikeThePodcast?`,
						},
					],
					hasModalEnd: false,
				},
			],
		},
	]
}
