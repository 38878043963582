//todo spostare nelal giusta dir
//inputId presi da console.debug di "Vue Rive Test"
import {genderStringToNumber} from '@/libraries/utils.js';
import {BaseQuestionObject} from '@/dataObjects/frameworkObjects/BaseQuestionObject.js';

const booksValue = answerValue => {
	switch(answerValue) {
		case '1': return 0;
		case '2': return 2;
		case '3': return 3;
		case '4': return 4;
		case '5': return 5;
		case '6': return 6;
		default: console.error('bad answerValue', answerValue); return 1;
	}
}

const dragAndDropValue = (answerValue, needles) => {
	//'musicalInstrumentsAdults:harmonic,guitar' >>> ['musicalInstrumentsAdults', 'harmonic,guitar']
	let values = answerValue?.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)

	if(!values?.length || !values[1]) {
		return false
	}

	values = values[1].split(BaseQuestionObject.ANSWER_SEPARATOR_SUBVALUES)

	return (Array.isArray(needles) ? needles : [needles]).find(needle => {
		return values.find(value => value === needle)
	})
}

const stringedInstruments = [
	'bass',
	'guitar',
	'harp',
	'ukulele',
	'viola',
	'violin',
]
const windInstruments = [
	'clarinet',
	'flute',
	'harmonic',
	'saxophone',
	'transverseFlute',
	'trumpet',
]
const percussionInstruments = [
	'drum',
	'drums',
	'maracas',
	'maracasAndRattles',
	'tambourine',
	'xylophone',
]

let riveMainSceneMap = [
	{
		inputId: 'main-jump-noBg',
		questionId: '',
	},
	{
		inputId: 'main-idle',
		questionId: '',
	},
	{
		inputId: 'main-jump',
		questionId: '',
	},
	{
		inputId: 'main-heightinput',
		questionId: '',
	},
	{
		inputId: 'main-widthinput',
		questionId: '',
	},
	{
		inputId: 'main-famiglia-genitore-color',
		questionId: 'area1personal_module2_page1_quest1',
	},
	{
		inputId: 'main-famiglia-partnerGenitore',
		questionId: 'area1personal_module1_page1_quest1',
		showOnCompletedPageId: 'area1personal_module2_page4',
	},
	// {
	// 	inputId: 'main-famiglia-figlioTarget',
	// 	questionId: 'area1personal_module2_page2_quest1',
	// 	value: () => 1,
	// },
	{
		inputId: 'main-famiglia-figlioTarget-color',
		questionId: 'area1personal_module2_page2_quest1',
	},
	{
		inputId: 'main-famiglia-figlioTarget-zaino',
		questionId: 'area1personal_module2_page3_quest1',
		value: answerValue => +(answerValue === '2')
	},
	{
		inputId: 'main-famiglia-partnerGenitore-color',
		questionId: 'area1personal_module2_page4_quest1',
	},
	{
		inputId: 'main-famiglia-partnerNonGenitore',
		questionId: 'area1personal_module1_page1_quest2',
		showOnCompletedPageId: 'area1personal_module2_page5',
	},
	{
		inputId: 'main-famiglia-partnerNonGenitore-color',
		questionId: 'area1personal_module2_page5_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli',
		questionId: 'area1personal_module1_page1_quest3',
		showOnCompletedPageId: 'area1personal_module2_page10',
		value: answerValue => +answerValue
	},
	{
		inputId: 'main-famiglia-altriFigli-1-color',
		questionId: 'area1personal_module2_page10_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-2-color',
		questionId: 'area1personal_module2_page11_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-3-color',
		questionId: 'area1personal_module2_page12_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-4-color',
		questionId: 'area1personal_module2_page13_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-5-color',
		questionId: 'area1personal_module2_page14_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-6-color',
		questionId: 'area1personal_module2_page15_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-7-color',
		questionId: 'area1personal_module2_page16_quest1',
	},
	{
		inputId: 'main-famiglia-altriFigli-8-color',
		questionId: 'area1personal_module2_page17_quest1',
	},
	{
		inputId: 'main-famiglia-nonni',
		questionId: 'area1personal_module1_page1_quest4',
		showOnCompletedPageId: 'area1personal_module2_page6',
		value: answerValue => +answerValue
	},
	{
		inputId: 'main-famiglia-nonni-1-color',
		questionId: 'area1personal_module2_page6_quest1',
	},
	{
		inputId: 'main-famiglia-nonni-2-color',
		questionId: 'area1personal_module2_page7_quest1',
	},
	{
		inputId: 'main-famiglia-altrePersone',
		questionId: 'area1personal_module1_page1_quest5',
		showOnCompletedPageId: 'area1personal_module2_page8',
		value: answerValue => +answerValue
	},
	{
		inputId: 'main-famiglia-altrePersone-1-color',
		questionId: 'area1personal_module2_page8_quest1',
	},
	{
		inputId: 'main-famiglia-altrePersone-2-color',
		questionId: 'area1personal_module2_page9_quest1',
	},
	{
		inputId: 'main-famiglia-animale',
		questionId: 'area1personal_module1_page1_quest6',
		showOnCompletedPageId: 'area1personal_module2_page9',
	},
	{
		inputId: 'main-libri-bambino',
		questionId: 'area1personal_module4_page1_quest1',
		value: booksValue,
	},
	{
		inputId: 'main-libri-adulto',
		questionId: 'area1personal_module4_page1_quest2',
		value: booksValue,
	},
	{
		inputId: 'main-strumenti-piano-bambino',
		questionId: 'area1personal_module4_page2_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'piano'),
	},
	{
		inputId: 'main-strumenti-corde-bambino',
		questionId: 'area1personal_module4_page2_quest1',
		value: answerValue => dragAndDropValue(answerValue, stringedInstruments),
	},
	{
		inputId: 'main-strumenti-fiato-bambino',
		questionId: 'area1personal_module4_page2_quest1',
		value: answerValue => dragAndDropValue(answerValue, windInstruments),
	},
	{
		inputId: 'main-strumenti-percussione-bambino',
		questionId: 'area1personal_module4_page2_quest1',
		value: answerValue => dragAndDropValue(answerValue, percussionInstruments),
	},
	{
		inputId: 'main-strumenti-piano-adulto',
		questionId: 'area1personal_module4_page3_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'piano'),
	},
	{
		inputId: 'main-strumenti-corde-adulto',
		questionId: 'area1personal_module4_page3_quest1',
		value: answerValue => dragAndDropValue(answerValue, stringedInstruments),
	},
	{
		inputId: 'main-strumenti-fiato-adulto',
		questionId: 'area1personal_module4_page3_quest1',
		value: answerValue => dragAndDropValue(answerValue, windInstruments),
	},
	{
		inputId: 'main-strumenti-percussione-adulto',
		questionId: 'area1personal_module4_page3_quest1',
		value: answerValue => dragAndDropValue(answerValue, percussionInstruments),
	},
	{
		inputId: 'main-devices-smartTV',
		questionId: 'area1personal_module4_page4_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'smartTv'),
	},
	{
		inputId: 'main-devices-pcFisso',
		questionId: 'area1personal_module4_page4_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'pc'),
	},
	{
		inputId: 'main-devices-pcPortatile',
		questionId: 'area1personal_module4_page4_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'notebook'),
	},
	{
		inputId: 'main-devices-smartphone',
		questionId: 'area1personal_module4_page4_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'smartphone'),
	},
	{
		inputId: 'main-devices-tablet',
		questionId: 'area1personal_module4_page4_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'tablet'),
	},
	{
		inputId: 'main-gioco-maschera',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'disguises'),
	},
	{
		inputId: 'main-gioco-pennello',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'creativeMaterial'),
	},
	{
		inputId: 'main-gioco-cucina',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'householdObjects'),
	},
	{
		inputId: 'main-gioco-memory',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'cognitives'),
	},
	{
		inputId: 'main-gioco-pupazzo',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'puppets'),
	},
	{
		inputId: 'main-gioco-altro',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'other'),
	},
	{
		inputId: 'main-gioco-bici',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'meansOfMovement'),
	},
	{
		inputId: 'main-gioco-lego',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'buildings'),
	},
	{
		inputId: 'main-gioco-macchina',
		questionId: 'area1personal_module4_page5_quest1',
		value: answerValue => dragAndDropValue(answerValue, 'toyCars'),
	},
]

riveMainSceneMap = riveMainSceneMap.map(entry => {

	if(!entry.value) {
		//forse e' una risposta di genere
		if(entry.inputId.endsWith('-color')) {
			entry.value = answerValue => genderStringToNumber(answerValue)
		}
		//default per la presenza delle cose/persone 1 >>> 1 e 2 >>> 0
		else {
			entry.value = answerValue => +(answerValue === '1')
		}
	}

	return entry
})


export {riveMainSceneMap}
