import {TimeusePageObject} from '@/dataObjects/area2observation/timeuse/TimeusePageObject.js';
import {onMounted} from 'vue';
import {useNavigationStore} from '@/stores/navigation.js';
import $ from 'jquery'
import {useArea2observationStateStore} from '@/stores/area2observationState.js';
import {TimeuseQuestionObject} from '@/dataObjects/area2observation/timeuse/TimeuseQuestionObject.js';
import {deleteRemoteTimeuseLoops} from '@/libraries/network.js';
import {replaceState} from '@/libraries/navUtils.js';
import moment from 'moment/moment';
import {usePage} from '@inertiajs/vue3';
import {ModuleTypes} from '@/dataObjects/frameworkObjects/ModuleTypes.js';

export class TimeuseModuleObject { // actually a very specialized ModuleObject!

	static TIME_START = 4
	static ACTIVITY_LOOPS_PAGE_INDEX = 2

	constructor(moduleData, props = {}) {
		this.type = ModuleTypes.TYPE_TIMEUSE
		this.index = moduleData.index
		this.isFirst = props.isFirst
		this.id = `area2observation_module${this.index}`
		this.navigationStore = useNavigationStore()
		this.areaStore = useArea2observationStateStore()
		this.title = moduleData.title || `Modulo ${this.index}`
		this.credits = moduleData.credits || ""
		this.hasModalEnd = moduleData.hasModalEnd
		this.accessibilityMonths0 = moduleData.accessibilityMonths0
		this.accessibilityMonths1 = moduleData.accessibilityMonths1
		this.accessibilityDays0 = moduleData.accessibilityDays0
		this.accessibilityDays1 = moduleData.accessibilityDays1
		/**
		 * @type {TimeusePageObject}
		 */
		this.activitiesPage = null
		/**
		 * @type {TimeusePageObject[]}
		 */
		this.pages = []
		/**
		 * @type {[TimeuseQuestionObject]}
		 */
		this.questions = []
		/**
		 * @type {null|Moment}
		 */
		this.objectDate = null
		/**
		 * @type {null|Moment}
		 */
		this.datetimeStart = null
		/**
		 * @type {null|Moment}
		 */
		this.datetimeEnd = null

		moduleData.pages?.forEach((page, index) => {
			const newPage = new TimeusePageObject(page, this, {
				index: index,
				isFirst: index === 0,
				isLast: index === moduleData.pages.length - 1,
			})

			this.pages.push(newPage)

			if(newPage.isActivitiesPage()) {
				this.activitiesPage = newPage
			}
		})
	}

	/**
	 * hydrate timeuse.questions from db
	 * hydrate timeuse.page.loops from db
	 * @param dbAnswers {[Object]} risposte anche di altri timeuse
	 */
	hydrate(dbAnswers) {
		const timeuseId = this.timeuseId()
		dbAnswers = dbAnswers.filter(answer => answer.timeuse_id === timeuseId)
		/**
		 * se le pagine non hanno il loop corrispondente ai dati del db, il loop va creato
		 * e le domande vanno idratate
		 */
		this.pages.forEach(page => {
			//console.log('page',page.index)
			const missingLoopsIds = []
			const pageDbAnswers = dbAnswers.filter(answer => answer.page_id === page.index)

			pageDbAnswers.forEach(answer => {
				if(
					//once loop_id only
					!missingLoopsIds.includes(answer.loop_id) &&
					//missing loop found
					!page.loops.find(loop => answer.loop_id === loop.index)
				) {
					missingLoopsIds.push(answer.loop_id)
				}
			})

			missingLoopsIds.forEach(loopId => {
				const newLoop = page.createNewLoop()
				//console.log('missing.createNewLoop',page.index, loopId, newLoop.id, page.loops.length)
			})
		})

		//idrata tutte le domande, queste sono presenti nei loop, anche quelli appena creati
		dbAnswers.forEach(dbAnswer => {
			//se non trova la domanda deve andare in errore
			try {
				this.questions
					.find(question => question.id === dbAnswer.total_question_id)
					.hydrate(dbAnswer)
			}
			catch(e) {
				console.error(this.id, this.questions.length, dbAnswer.total_question_id, e)
			}
		})

		this.pages.forEach(page => {
			//dopo la creazione dei loop mancanti in base alle dbAnswers e l'idratazione delle domande
			// posso sapere se timeuse.areActivityLoopsCompleted, in caso contrario creo un nuovo loop vuoto da
			// elaborare in frontend
			if(page.isActivitiesPage()) {
				if(!this.areActivityLoopsCompleted()) {
					const lastLoop = page.lastLoop()

					if(!lastLoop || page.lastLoop().isCompleted()) {
						const newLoop = page.createNewLoop()
						//console.log('createNewLoop', this.id, newLoop.id)
					}
				}
			}
			//le altre pagine hanno un solo loop, ovvero il loop e' fittizio e serve solo ad
			//avere la medesima struttura per tutte le pagine
			else if(!page.loops.length) {
				page.createNewLoop()
			}
		})
	}

	isOpened() {
        console.debug("Checking timeuse opening...")
		const loop = this.pages[0].lastLoop()
        let check = !!loop?.questions.find(question => question.isSeen() || question.isAnswered())
        console.debug("HAS THIS TIMEUSE BEEN OPENED AT LEAST ONCE? ", check)
		return check
	}

	/**
	 * le attivita sono bloccate se ho una qualsiasi risposta vista/risposta successiva alla pagina delle attivita'
	 * @returns {boolean}
	 */
	areActivityLoopsLocked() {
		if(!this.areActivityLoopsCompleted()) {
			return false
		}

		const page = this.pages.find(page => {
			return (
				page.index > TimeuseModuleObject.ACTIVITY_LOOPS_PAGE_INDEX &&
				page.loops[0]?.questions.length
			)
		})

		return !!page?.lastLoop().questions.find(question => question.isSeen() || question.isAnswered())
	}

	areActivityLoopsCompleted() {
		if(!this.datetimeEnd) {
			return false
		}
		const datetimeEndLastLoop = this.datetimeEndLastLoop()
		if(!datetimeEndLastLoop) {
			return false
		}

		return datetimeEndLastLoop.isSameOrAfter(this.datetimeEnd)
	}

	/**
	 * @returns {moment.Moment|null}
	 */
	datetimeEndLastLoop() {
		const lastCompletedLoop =  this.activitiesPage.loops.filter(loop => loop.isCompleted()).reverse()[0]
		return lastCompletedLoop?.datetimeEnd(true)
	}

	/**
	 * accessibile se il bimbo ha un numero di mesi pari o piu di accessibilityMonths0 e
	 * se il modulo è completato oppure
	 * se non è completato e il bimbo ha meno mesi di accessibilityMonths1
	 * @param childBirthdate {Moment}
	 * @returns {boolean}
	 */
	isAccessible = childBirthdate => {
		if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}
		const accessibility = this._getAccessibilityDates(childBirthdate)

		const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()

		return (
			nowCalc.isSameOrAfter(accessibility.date0) &&
			(
				this.isCompleted() ||
				(!this.isCompleted() && nowCalc.isBefore(accessibility.date1))
			)
		)
	}

	isNotLongerAccessible(childBirthdate){
		if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}
		if(this.isFirst) {
			return true
		}
		const accessibility = this._getAccessibilityDates(childBirthdate)

		const nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()

		return nowCalc.isSameOrAfter(accessibility.date1)
	}

	/**
	 * @param childBirthdate {Moment}
	 * @returns {{date0: Moment, date1: Moment}}
	 * @private
	 */
	_getAccessibilityDates(childBirthdate) {
		return {
			date0: childBirthdate?.clone()
				.add(this.accessibilityMonths0, 'months')
				.add(this.accessibilityDays0, 'days')
				.startOf('day'),
			date1: childBirthdate?.clone()
				.add(this.accessibilityMonths1, 'months')
				.add(this.accessibilityDays1, 'days')
				.endOf('day')
		}
	}

	//il timeuse e' completato se tutte pagine accessibili sono completate
	isCompleted = () => {
		return !this.pages.find(page => !page.isCompleted())
	}

	//i loop del TIMEUSE sono completato se sono state coperte tutte le 24 ore
	/**
	 * vengono considerate solo le pagine con domande, una pagina è completa se ha TUTTE le risposte
	 * @returns {number}
	 */
	completionPerc() {
		const datetimeEndLastLoop = this.datetimeEndLastLoop()

		if(!datetimeEndLastLoop) {
			return 0
		}
		const completedMinutes = datetimeEndLastLoop.diff(this.datetimeStart, 'minutes')

		return Math.min(100, Math.ceil(100 * completedMinutes / (24 * 60)))
	}

	async deleteLoops(){
		try {
			await deleteRemoteTimeuseLoops(this.timeuseId())
		} catch(e) {
			console.error('Page.save catch', this, e);
		}
	}

	/**
	 * @param questionSupertype {string}
	 * @returns {TimeuseQuestionObject[]}
	 */
	getQuestionsBySupertype(questionSupertype) {
		const questions = []
		this.pages.forEach(page => {
			const pageSuperType = page.getQuestionsBySupertype(questionSupertype)
			if(pageSuperType && pageSuperType.length){
				questions.push(pageSuperType)
			}
		})
		return questions
	}

	timeuseId() {
		switch(this.index) {
			case(5): return 1
			case(9): return 2
			case(13): return 3
			default: console.error('bad timeuse', this.index); return 0;
		}
	}

	getPageById(pageId) {
		const page = this.pages.find(page => page.id === pageId)
		if(!page) {
			console.warn('pageId not found', pageId)
			return null
		}
		return page
	}

	/**
	 * azioni da fare per ogni pagina
	 * 1) controlli di accessibilita' delle pagine e navigazione forzata
	 * 2) registrare per la prima volta tutte le risposte "not_seen"
	 * 3) layout fix
	 *
	 * vanno fatti in onMounted perche l'utente puo arrivare in una pagina in modi
	 * diversi (url diretto, bug di navigazione, navigazione da cronologia)
	 *
	 * @param index {Number}
	 * @returns {TimeusePageObject}
	 */
	pageSetup(index) {
		const user = usePage().props.user
		const childBirthdate = moment(user?.child_birthdate)

		const page = this.pages.find(page => page.index === index)
		//console.debug('pageSetup', index, page, page.questions)

		onMounted(async () => {
			//console.debug('pageSetup onMounted', index)

			//modulo bloccato
			if(!this.isAccessible(childBirthdate)) {
				await this.navigationStore.goHome()
				return
			}

			//quando il timeuse è terminato si aprela schermata di riepilogo
			if(this.isCompleted()) {
				await this.navigationStore.goToPage( this.pages.find(page => page.index === 3).id )
			}

			/**
			 * se questa pagina non è completata, controllo che sia la prima da completare, altrimenti vado lì
			 */
			if(!this.isCompleted() && !page.isCompleted()) {
				const firstUncompletedPage = this.pages.find(page => !page.isCompleted())

				if(page.id !== firstUncompletedPage.id) {
					await this.navigationStore.goToPage(firstUncompletedPage.id)
					return
				}
			}

			//2) registrare per la prima volta tutte le risposte "not_seen"
			if(page.isNotSeen()) {
				page.setAsSeen()
				await page.save()
			}

			// 3) aggiunge l'hash all'url, indica il numero del loop
			if(page.isActivitiesPage()) {
				const url = new URL(window.location)
				url.hash = '#' + page.loops.length
				replaceState(url.toString())
			}

			//4) layout fix
			const safetyOffset = 70
			$('.scrollbar__scroller').height($('main').height() - safetyOffset)
		})

		return page
	}

	//debug start
	complete() {
		this.pages.forEach(page => page.complete())
	}
	setAsNotSeen() {
		this.pages.forEach(page => page.setAsNotSeen())
	}
	setAsSeen() {
		this.pages.forEach(page => page.setAsSeen())
	}
	//debug end
}
