<script	setup>

import {compClass} from '@/libraries/utils.js';

const props = defineProps({
	tags: Array
})

const tagBgClass = tagName => {
	switch(tagName) {
		case ('language'): return 'green';
		case ('attention'): return 'pink';
		case ('movement'): return 'purple';
		case ('memory'): return 'white';
		case ('emotions'): return 'yellow';
		default: return 'white';
	}
}
</script>

<template>
	<div class="flex flex-wrap" :class="compClass()">
		<div v-for="(tagName, index) in props.tags" :key="index"
			class="px-2 mr-2 mb-3 text-sm rounded-full first-letter:lowercase font-semibold text-black"
			:class="'bg-'+tagBgClass(tagName)">
			{{ $tg(`tags.${tagName}`) }}
		</div>
	</div>
</template>
