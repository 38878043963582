import { defineStore } from 'pinia'
import {ModuleTypes} from '@/dataObjects/frameworkObjects/ModuleTypes.js'
import {TimeuseModuleObject as TimeuseModuleObject} from '@/dataObjects/area2observation/timeuse/TimeuseModuleObject.js'
import {ModuleObject as StandardModuleObject} from '@/dataObjects/area2observation/ModuleObject.js'
import { WordsModuleObject } from '@/dataObjects/area2observation/words/WordsModuleObject.js'
import {useRoute} from 'vue-router';
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';

const id = 'area2observationState'
export const useArea2observationStateStore = defineStore(id, {
	state: () => {
		/*VARIABILI DI STATO*/
		return {
			modules: [],
			words: [],
			questions: [],
			modulesInitialized: false,
			timeuseActivityAnswer: '',
		}
	},
	/*VARIABILI CALCOLATE*/
	// since we rely on `this`, we cannot use an arrow function
	getters: {
		getSession(state) {
			return state.browserSessionId
		},
	},
	/*FUNZIONI*/
	// since we rely on `this`, we cannot use an arrow function
	actions: {
		/**
		 *
		 * @param dataModules {Array}
		 * @param dbAnswers {Array}
		 * @param dbTimeuseAnswers {Array}
		 * @param dbWordAnswers {Array}
		 */
		create(dataModules, dbAnswers, dbTimeuseAnswers, dbWordAnswers) {
			console.debug(id, 'create', dbAnswers.length)

			//once only
			if(this.modulesInitialized) {
				console.debug(id, 'create skip', this.modules.length)
				return
			}

			dataModules.forEach((module, index) => {
				//create objects
				if((module.type !== ModuleTypes.TYPE_TIMEUSE) && (module.type !== ModuleTypes.TYPE_WORDS)) {
					const newModule = new StandardModuleObject(module, {
						index: index,
						isFirst: index === 0,
						isLast: index === dataModules.length - 1,
						areaStoreFunction: useArea2observationStateStore,
						areaId: "area2observation_module",
						areaName: AreaObject.AREA2_OBSERVATION
					})

					this.modules.push(newModule)

					//tutte le domande vanno in this.questions
					newModule.questions.forEach(question => this.questions.push(question))
				}
				else if ((module.type === ModuleTypes.TYPE_TIMEUSE)){
					const newModule = new TimeuseModuleObject(module)

					this.modules.push(newModule)

					const timeuseId = newModule.timeuseId()

					newModule.hydrate(dbTimeuseAnswers.filter(answer => answer.timeuse_id === timeuseId))
				}
				else if ((module.type === ModuleTypes.TYPE_WORDS)){
					const newModule = new WordsModuleObject(module, {
						index: index,
						isFirst: index === 0,
						isLast: index === dataModules.length - 1,
						areaStoreFunction: useArea2observationStateStore,
						areaId: "area2observation_module",
						areaName: AreaObject.AREA2_OBSERVATION,
						words: dbWordAnswers
					})

					this.modules.push(newModule)

					//tutte le words vanno in this.words
					newModule.words.forEach(word => this.words.push(word))
				}
			})

			this.hydrate(dbAnswers)

			this.modulesInitialized = true

			//dev window[id] = unref(this)
		},

		/**
		 * @param dbAnswers {Array}
		 */
		hydrate(dbAnswers) {
			//console.debug(id, 'hydrate', dbAnswers.length)

			dbAnswers.forEach(dbAnswer => {
				try {
					this.questions
						//se non trova la domanda deve andare in errore
						.find(question => question.id === dbAnswer.total_question_id)
						.hydrate(dbAnswer)
				}
				catch(e) {
					console.error('no question', dbAnswer.total_question_id, e)
				}
			})
		},

		getModule(index) {
			return this.modules.find(module => module.index === index)
		},
		/**
		 * @returns {?ModuleObject}
		 */
		currentModule() {
			const currentRoute = useRoute()
			//module0_page1 >> module0
			const moduleId = currentRoute.name.replace(/_.+$/, '')

			return this.modules.find(module => module.id === moduleId)
		},
	},
	persist: true
})
